import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  DataTable,
  DataTableStateEvent,
  DataTableProps,
  DataTableValueArray,
  DataTableFilterMeta,
} from "primereact/datatable";
import { isEqual } from "../";
import {
  AdminTableHasuraAdapter,
  AdminTableAdapterState,
} from "./AdminTableAdapter";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { Column, ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import InputTextDebounced from "../InputTextDebounced";
import useLayoutDimensions from "hooks/useLayoutDimensions";
import { utils, write, writeFile } from "xlsx";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from "primereact/progressbar";

export interface AdminTableState {
  page: number; // 0 indexed page
  rowsPerPage: number;
  filters: DataTableFilterMeta;
  sortField: string;
  sortOrder: 1 | 0 | -1 | null | undefined;
  globalFilter: string;
  globalFilterFields: string[];
}

type AdminTableProps<TValue extends DataTableValueArray> = {
  adapter: AdminTableHasuraAdapter;
  initialState: AdminTableState;
  stateOverride: Partial<AdminTableState>;
  onStateEvent?: (e: AdminTableState) => void;
  includeColumnsToggler?: boolean;
  defaultFieldsSelected?: ColumnProps["field"][];
  includeGlobalFilter?: boolean;
  globalFilterPlaceholder?: string;
  onClear?: () => void;
  tableHeight?: number;
} & DataTableProps<TValue>;

const tableTopMargin = 8;

const _AdminTable: React.ForwardRefRenderFunction<
  DataTable<any>,
  AdminTableProps<any>
> = (props, ref) => {
  const {
    adapter,
    initialState,
    stateOverride,
    onStateEvent,
    children,
    includeColumnsToggler,
    defaultFieldsSelected,
    includeGlobalFilter,
    globalFilterPlaceholder,
    header,
    tableHeight,
    ...rest
  } = props;

  const [adminTableAdapterState, setAdminTableAdapterState] = useState<
    Partial<AdminTableAdapterState>
  >({});

  const previousStateRef = useRef<AdminTableState | undefined>();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const columns = useMemo<Column[]>(() => {
    return React.Children.toArray(children) as any;
  }, [children]);
  const columnProps = useMemo<ColumnProps[]>(() => {
    return columns.map((column) => column.props);
  }, [columns]);
  const [selectedColumns, setSelectedColumns] = useState<ColumnProps[]>(() => {
    return columnProps.filter(
      (option) =>
        defaultFieldsSelected === undefined ||
        defaultFieldsSelected?.includes(option.field)
    );
  });

  const fields = useMemo<string[]>(() => {
    return columns.map((column) => column.props.field ?? "");
  }, [columns]);

  const { windowHight, outletPadding, outletWidth } = useLayoutDimensions();
  const dataTableContainerRef = useRef<HTMLDivElement>(null);
  const [scrollHeight, setScrollHeight] = useState(400);

  const [showExportModal, setShowExportModal] = useState(false);
  const [exportCurrent, setExportCurrent] = useState(0);
  const [exportTotal, setExportTotal] = useState(0);

  useEffect(() => {
    const paginatorHeight = 72;
    if (tableHeight) {
      setScrollHeight(tableHeight - paginatorHeight);
    } else {
      let newScrollHeight =
        windowHight -
        (dataTableContainerRef.current?.offsetTop ?? 0) -
        paginatorHeight -
        tableTopMargin;
      setScrollHeight(newScrollHeight);
    }
  }, [windowHight, outletPadding, tableHeight]);

  const currentState = {
    ...initialState,
    ...previousStateRef.current,
  };

  const handleUpdate = async (
    e: Partial<AdminTableState>,
    from: string
  ): Promise<void> => {
    const newState: AdminTableState = {
      ...currentState,
      ...e,
      filters: {
        ...currentState.filters,
        ...e.filters,
      },
    };
    // console.log(
    //   "handleUpdate from",
    //   from
    //   // isEqual(newState, previousState),
    //   // newState,
    //   // previousState
    // );
    if (!isEqual(newState, previousStateRef.current) || from === "reload") {
      previousStateRef.current = newState;
      onStateEvent?.(newState);
      setLoading(true);
      const newAdminTableAdapterState = await adapter.handleAdminTableState(
        newState,
        fields
      );
      setAdminTableAdapterState(newAdminTableAdapterState);
      setLoading(false);
    }
  };

  const clear = () => {
    handleUpdate(initialState, "reset");
    props.onClear?.();
  };

  const onFilter = (e: DataTableStateEvent): void => {
    handleUpdate(
      {
        filters: e.filters,
      },
      "onFilter"
    );
    props.onFilter?.(e);
  };

  const onSort = (e: DataTableStateEvent): void => {
    handleUpdate(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
      },
      "onSort"
    );
    props.onSort?.(e);
  };

  const onPage = (e: DataTableStateEvent): void => {
    handleUpdate(
      {
        page: e.page,
        rowsPerPage: e.rows,
      },
      "onPage"
    );
    props.onPage?.(e);
  };

  const updateGlobalFilter = (globalFilter: string) => {
    handleUpdate(
      {
        globalFilter,
      },
      "updateGlobalFilter"
    );
  };

  useEffect(() => {
    const listener = () => {
      if (!loading) {
        handleUpdate(currentState, "reload");
      }
    };

    adapter.on("reload", listener);

    return () => {
      adapter.removeListener("reload", listener);
    };
  }, [adapter, currentState, loading]);

  useEffect(() => {
    handleUpdate(stateOverride, "useEffect stateOverride");
    setSearchText((previousSearchText) => {
      if (
        typeof stateOverride.globalFilter === "string" &&
        stateOverride.globalFilter !== searchText
      ) {
        return stateOverride.globalFilter;
      }
      return previousSearchText;
    });
  }, [stateOverride]);

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columnProps.filter((column) =>
      selectedColumns.find(
        (columnProps: ColumnProps) => columnProps.field === column.field
      )
    );
    setSelectedColumns(orderedSelectedColumns);
  };

  const toggledColumns = useMemo<Column[]>(() => {
    return columns.filter(
      (column) =>
        selectedColumns.find(
          (columnProps) => columnProps.field === column.props.field
        ) !== undefined
    );
  }, [selectedColumns, columns]);

  const handleExport = async () => {
    setShowExportModal(true);
    setExportTotal(0);

    const typename = adapter.dataAdapter.typename;
    const records = await adapter.fetchAllAsRecords(
      currentState,
      fields,
      columnProps,
      (current, total) => {
        setExportCurrent(current);
        setExportTotal(total);
      }
    );

    const worksheet = utils.json_to_sheet(records);
    let workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "sheet");
    write(workbook, { bookType: "csv", type: "buffer" });
    write(workbook, { bookType: "csv", type: "binary" });
    writeFile(workbook, `${typename}.csv`);

    setShowExportModal(false);
  };

  return (
    <>
      <Dialog
        visible={showExportModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowExportModal(false)}
        closable={false}
      >
        <div>
          <h1 className="text-3xl font-bold my-4">
            Exporting {adapter.dataAdapter.typename} records
          </h1>
          {exportTotal > 0 && (
            <div>
              <div>
                {exportCurrent} out of {exportTotal} records exported.
              </div>
              <ProgressBar
                value={Math.round((exportCurrent / exportTotal) * 100)}
              />
            </div>
          )}
        </div>
      </Dialog>
      {includeColumnsToggler && (
        <MultiSelect
          style={{
            maxWidth: outletWidth - outletPadding * 2,
          }}
          value={selectedColumns}
          options={columnProps}
          optionLabel="header"
          onChange={onColumnToggle}
          filter
          placeholder="Select a Column"
          display="chip"
        />
      )}
      {includeGlobalFilter && (
        <div className="flex justify-between flex-wrap gap-3 mt-2">
          <div className="flex flex-row flex-wrap gap-3">
            <InputTextDebounced
              value={searchText}
              onChangeTextDebounced={(text) => {
                setSearchText(text);
                updateGlobalFilter(text);
              }}
              placeholder={globalFilterPlaceholder}
              style={{ width: 400 }}
            />
            <Button onClick={clear}>
              Clear
              <i className="pi pi-filter-slash ml-3" />
            </Button>
          </div>

          <div>
            <Button
              onClick={handleExport}
              tooltip="Export CSV"
              tooltipOptions={{ position: "left" }}
            >
              <i className="pi pi-file" />
            </Button>
            <Button
              className="ml-3"
              onClick={() => {
                adapter.reload();
              }}
            >
              <i className="pi pi-refresh" />
            </Button>
          </div>
        </div>
      )}
      {header}
      <div ref={dataTableContainerRef}>
        <DataTable
          style={{
            marginTop: tableTopMargin,
            marginBottom: 0,
          }}
          {...rest}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rowsPerPageOptions={
            props.rowsPerPageOptions || [10, 25, 50, 100, 500]
          }
          loading={loading}
          value={adminTableAdapterState.current}
          sortField={adminTableAdapterState.sortField}
          sortOrder={adminTableAdapterState.sortOrder}
          filters={adminTableAdapterState.filters}
          onFilter={onFilter}
          onSort={onSort}
          onPage={onPage}
          paginator
          lazy
          first={adminTableAdapterState.first}
          rows={adminTableAdapterState.rows}
          totalRecords={adminTableAdapterState.total}
          ref={ref}
          scrollable
          scrollHeight={`${scrollHeight}px`}
        >
          {toggledColumns as React.ReactNode}
        </DataTable>
      </div>
    </>
  );
};

export const AdminTable = React.forwardRef(_AdminTable);
