import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { HasuraDataAdapter, buildOrderBy } from "../../DataAdapter";
import { AdminInputBaseProps, validateProps } from "../AdminForm";
import { DataTableSortEvent } from "primereact/datatable";

interface InputRelationProps {
  relationshipColumnNameForLabel: string;
  relationshipColumnNameForValue: string;
  adapter: HasuraDataAdapter;
  where?: Record<string, any>;
  sortField?: string,
  sortOrder?: DataTableSortEvent["sortOrder"],
}

export type AdminInputRelationProps = DropdownProps &
  AdminInputBaseProps &
  InputRelationProps;
type OptionProps = { value: any; label: string };

const AdminInputRelation: React.FC<AdminInputRelationProps> = (props) => {
  validateProps(props);
  const {
    control,
    name,
    label,
    helpText,
    containerClassName,
    required,
    attributeType,
    adapter,
    relationshipColumnNameForLabel,
    relationshipColumnNameForValue,
    where,
    sortField,
    sortOrder,
    defaultValue,
    onBlur,
    ...baseProps
  } = props;

  const priorityLabel = attributeType?.label ?? label ?? name;

  const [options, setOptions] = useState<OptionProps[]>();
  const [loading, setLoading] = useState<any>(false);

  const itemTemplate = (tOptions: any): ReactNode => {
    const className = classNames("custom-scroll-item scroll-item", {
      odd: tOptions.odd,
    });
    return loading ? (
      <div className={className}>
        <div className="flex align-items-center px-2 my-2">
          <Skeleton width="100%" height="2.5rem" />
        </div>
        <div className="flex align-items-center px-2 my-2">
          <Skeleton width="100%" height="2.5rem" />
        </div>
      </div>
    ) : (
      <div>{tOptions.label}</div>
    );
  };

  const getOptions = useCallback(async (): Promise<void> => {
    setLoading(true);
    const orderBy = buildOrderBy(adapter.namingConvention, sortField, sortOrder);

    const result = await adapter?.infiniteManyQuery<any>({ where, orderBy });
    const items = result?.current?.map?.((item) => ({
      value: item?.[relationshipColumnNameForValue],
      label: item?.[relationshipColumnNameForLabel],
    }));
    setOptions(items || []);
    setLoading(false);
  }, [
    adapter,
    relationshipColumnNameForLabel,
    relationshipColumnNameForValue,
    where,
  ]);

  useEffect(() => {
    if (!options) {
      getOptions();
    }
  }, [getOptions, options]);

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={{
        required,
        ...attributeType?.validation,
      }}
      render={({
        field: { ref, onChange, ...inputProps },
        fieldState: { invalid, error },
        formState: _formState,
      }) => {
        const errorMessage = error?.message || error?.type;

        return (
          <div className={containerClassName}>
            <div className="p-float-label">
              <Dropdown
                {...inputProps}
                className={`w-full ${invalid ? "p-invalid" : ""}`}
                onChange={(e) => {
                  if (props.onSelect) {
                    props.onSelect(e.value);
                  }
                  onChange({ target: { value: e.value } });
                }}
                onBlur={() => {
                  inputProps.onBlur();
                  onBlur && onBlur();
                }}
                ref={ref}
                filterBy="label"
                options={options}
                itemTemplate={itemTemplate}
                filter
                showClear
                {...baseProps}
              />
              <label htmlFor={name}>{priorityLabel}</label>
            </div>
            <small id={`${name}-help`} className="p-d-block">
              {helpText}
            </small>
            <small className="p-error p-d-block">{errorMessage}</small>
          </div>
        );
      }}
    />
  );
};

export default AdminInputRelation;
