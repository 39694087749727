import React from 'react'

interface DashboardSmallCardProps {
  iconClassName: string // pi pi-user
  mainText: string
  secondaryText: string
}
export const DashboardSmallCard: React.FC<DashboardSmallCardProps> = ({ iconClassName, mainText, secondaryText }) => (
  <div className="w-full p-surface-header rounded-full shadow-xl p-text-primary">
    <div className="py-2 px-4">
      <div className="flex justify-between items-center">
        <div
          className="rounded-full p-primary-text-color p-background-primary-color flex justify-center items-center"
          style={{ width: '2.5rem', height: '2.5rem' }}
        >
          <i className={`${iconClassName} text-2xl`} />
        </div>
        <div className="pl-4 w-full flex-1">
          <div>
            <div className="text-2xl font-bold leading-8">{mainText}</div>
            <div className="mt-1 text-base">{secondaryText}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
