import GroupDetail from "components/Group/GroupDetail";
import PostsTable from "components/Post/PostsTable";
import UsersTable from "components/User/UsersTable";
import { GroupFieldsFragment } from "graphql/generated/resourceApi";
import { TabPanel, TabView } from "primereact/tabview";
import React from "react";

interface GroupTabsProps {
  onUpdate: () => void;
  group: GroupFieldsFragment;
}

const GroupTabs: React.FC<GroupTabsProps> = ({ group, onUpdate }) => {
  return (
    <TabView style={{ padding: "0 1rem" }}>
      <TabPanel header="Details">
        <GroupDetail group={group} onUpdate={onUpdate} />
      </TabPanel>
      <TabPanel header="Users">
        <UsersTable groupId={group.id} hideHeader />
      </TabPanel>
      <TabPanel header="Posts">
        <PostsTable groupId={group.id} hideHeader />
      </TabPanel>
    </TabView>
  );
};

export default GroupTabs;
