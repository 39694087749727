import { useState, useEffect, useCallback } from "react";

export default function useDebounce(debounceMillisconds: number): (callback: () => void) => void {
  const [timeoutId, setTimeoutId] = useState<any>();
  const [shouldResetTimeout, setShouldResetTimeout] = useState(false);
  const [callback, setCallback] = useState(() => {
    return () => {};
  });

  const debounce = useCallback((newCallback: () => void) => {
    setShouldResetTimeout(true);
    setCallback(() => {
      return newCallback;
    });
  }, []);

  useEffect(() => {
    if (shouldResetTimeout) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setTimeoutId(setTimeout(callback, debounceMillisconds));
      setShouldResetTimeout(false);
    }
  }, [timeoutId, shouldResetTimeout, callback]);

  return debounce;
}
