import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as JotaiProvider } from "jotai";
import AppRouter from "shared/routes/AppRouter";
import { UserProvider } from "shared/UserContext";
import reportWebVitals from "reportWebVitals";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import "../theme.css";
import "index.css";
import { AuthProvider } from "react-oidc-context";

const {
  VITE_APP_AUTH_URL: authUrl,
  VITE_APP_SITE_URL: siteUrl,
  VITE_APP_AUTH_CLIENT_ID: clientId,
} = import.meta.env;

function onSigninCallback(_user: any): void {
  window.history.replaceState({}, document.title, window.location.pathname);
}

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <JotaiProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <AuthProvider
            scope="openid email crwdid"
            authority={authUrl}
            client_id={clientId}
            redirect_uri={siteUrl}
            response_type="code"
            onSigninCallback={onSigninCallback}
          >
            <UserProvider>
              <AppRouter />
            </UserProvider>
          </AuthProvider>
        </BrowserRouter>
      </Suspense>
    </JotaiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
