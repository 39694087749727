/* eslint-disable no-await-in-loop */
import {
  AdminTable,
  AdminTableState,
  dateFilterTemplate,
  dateTimeBody,
} from "components/Admin";
import { GroupRequestFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { PostAdminTableFieldsFragment } from "graphql/generated/resourceApi";
import { useDataAdapter } from "hooks/useDataAdapter";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";

export interface PostReportsTableProps {
  post?: PostAdminTableFieldsFragment;
  hideHeader?: boolean;
  tableHeight?: number;
  disableRowClick?: boolean;
}

export const imageSize = 96;

const GroupRequestTable: React.FC<PostReportsTableProps> = ({
  hideHeader,
  tableHeight,
}) => {
  const toast = useRef<Toast>(null);

  const { tableAdapter } = useDataAdapter(
    "groupRequest",
    GroupRequestFieldsFragmentDoc
  );

  const initialFilters = {
    city: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    region: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    country: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.firstName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.lastName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: [
      "user.primaryEmail",
      "user.firstName",
      "user.lastName",
      "city",
      "region",
      "country",
    ],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
  });

  return (
    <div>
      <Toast ref={toast} />
      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-postComment text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Collective Requests</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={setTableState}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        tableHeight={tableHeight}
        onRowSelect={(_event: DataTableSelectEvent) => {}}
      >
        <Column
          field="city"
          header="Location"
          sortable
          filter
          style={{
            minWidth: "400px",
          }}
          body={(data) => {
            return [data.city, data.region, data.country]
              .filter((item) => !!item)
              .join(" ");
          }}
        />
        <Column
          field="user.primaryEmail"
          header="Email"
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="user.firstName"
          header="First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.lastName"
          header="Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="createdAt"
          header="Requested On"
          body={dateTimeBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default GroupRequestTable;
