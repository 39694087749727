/* eslint-disable no-await-in-loop */
import {
  AdminTable,
  AdminTableState,
  booleanBody,
  booleanFilterTemplate,
  dateFilterTemplate,
  dateTimeBody,
} from "components/Admin";
import { UserPostReportFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { PostAdminTableFieldsFragment } from "graphql/generated/resourceApi";
import { useDataAdapter } from "hooks/useDataAdapter";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import React, { ReactElement, useRef, useState } from "react";
import { useAuthToken } from "shared/UserContext";
import { formatTextWithMentions } from "utils/postUtils";

export interface PostReportsTableProps {
  post?: PostAdminTableFieldsFragment;
  hideHeader?: boolean;
  tableHeight?: number;
  disableRowClick?: boolean;
}

export const imageSize = 96;

const PostReportsTable: React.FC<PostReportsTableProps> = ({
  post,
  hideHeader,
  tableHeight,
}) => {
  const toast = useRef<Toast>(null);
  const token = useAuthToken();
  const [showPostModal, setShowPostModal] = useState<boolean>(false);
  const [_currentPost, setCurrentPost] = useState<
    PostAdminTableFieldsFragment | undefined
  >();

  const { tableAdapter } = useDataAdapter(
    "userPostReport",
    UserPostReportFieldsFragmentDoc,
    post ? { postId: { _eq: post.id } } : {}
  );
  const dialogRef = useRef<Dialog>(null);
  const [_dialogHeight, setDialogHeight] = useState(0);

  const initialFilters = {
    "post.body": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "user.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.firstName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.lastName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    report: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    hide: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const imageCell = (row: any): ReactElement => {
    console.log(row?.post);
    const url = row?.post?.postImages?.[0]?.file
      ? `${row.post.postImages[0].file.url}&token=${token}`
      : undefined;

    if (url) {
      return <Image src={url} style={{ width: imageSize }} />;
    }
    return <></>;
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["post.body"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
  });

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={showPostModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowPostModal(false)}
        ref={dialogRef}
        onResize={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
        onShow={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
      >
        <h1 className="text-3xl font-bold m-4">
          View post from main posts table to see details
        </h1>
      </Dialog>
      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-postComment text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Post Reports</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={setTableState}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search by post body"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        tableHeight={tableHeight}
        onRowSelect={(e: DataTableSelectEvent) => {
          setCurrentPost(e.data);
          setShowPostModal(true);
        }}
      >
        <Column
          field="post.postImages"
          header="Image"
          body={imageCell}
          style={{ minWidth: imageSize + 24 }}
        />
        <Column
          field="post.body"
          header="Body"
          sortable
          filter
          style={{
            minWidth: "400px",
            maxWidth: "600px",
            overflow: "hidden",
          }}
          body={(row) => formatTextWithMentions(row.post.body)}
        />
        <Column
          field="user.primaryEmail"
          header="Email"
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="user.firstName"
          header="First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.lastName"
          header="Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column field="report" header="Report" sortable filter />
        <Column
          field="hide"
          header="Hide"
          sortable
          body={booleanBody}
          dataType="boolean"
          filter
          filterElement={booleanFilterTemplate}
        />
        <Column
          field="createdAt"
          header="Reported On"
          body={dateTimeBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default PostReportsTable;
