import { textToFormattedTexts } from "./textUtils";

export function formatTextWithMentions(body: string | null | undefined): string {
    // return body ?? "";
  if (!body) {
    return "";
  }
  const formattedTexts = textToFormattedTexts(body);
  return formattedTexts
    .map((formattedText) => formattedText.displayText)
    .join("");
}
