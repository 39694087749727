import React from "react";
import { PostAdminTableFieldsFragment } from "graphql/generated/resourceApi";
import { TabPanel, TabView } from "primereact/tabview";
import PostCommentsTable from "components/Post/PostCommentsTable";
import PostDetail from "./PostDetail";
import PostReportsTable from "./PostReportsTable";

export interface PostProps {
  post: PostAdminTableFieldsFragment;
  onUpdate: ({
    title,
    message,
    post,
  }: {
    title: string;
    message: string;
    post?: PostAdminTableFieldsFragment;
  }) => void;
  tableHeight?: number;
}

const PostTabs: React.FC<PostProps> = ({post, onUpdate, tableHeight}) => {
  return (
    <TabView style={{ padding: "0 1rem" }}>
      <TabPanel header="Details">
        <PostDetail post={post} onUpdate={onUpdate} />
      </TabPanel>
      <TabPanel header="Comments">
        <PostCommentsTable onRowClick={() => {}} post={post} tableHeight={tableHeight} hideHeader />
      </TabPanel>
      <TabPanel header="Reports">
        <PostReportsTable post={post} tableHeight={tableHeight} hideHeader disableRowClick />
      </TabPanel>
    </TabView>
  );
};

export default PostTabs;
