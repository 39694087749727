import { useMemo } from "react";
import { GraphQLClient } from "graphql-request";
import { DocumentNode } from "graphql";
import {
  HasuraDataAdapter,
  HasuraGraphQLNamingConvention,
  AdminTableHasuraAdapter,
  WhereClause,
} from "components/Admin";
import { useClient } from "shared/UserContext";
import {} from "components/Admin/AdminTable/adminTableUtils";

export const hasuraNamingConvention: HasuraGraphQLNamingConvention = "graphqlDefault";

export interface DataAdapters {
  dataAdapter: HasuraDataAdapter;
  tableAdapter: AdminTableHasuraAdapter;
}

export function useDataAdapter(
  typename: string,
  fieldsFragment: DocumentNode,
  baseWhere?: WhereClause,
  client?: GraphQLClient
): DataAdapters {
  const contextClient = useClient();
  const dataAdapter = useMemo(
    () =>
      new HasuraDataAdapter(
        client ?? contextClient,
        typename,
        fieldsFragment,
        hasuraNamingConvention
      ),
    [typename, fieldsFragment, client, contextClient]
  );
  const tableAdapter = useMemo(
    () => new AdminTableHasuraAdapter(dataAdapter, baseWhere),
    [dataAdapter, baseWhere]
  );

  return { dataAdapter, tableAdapter };
}
