/* eslint-disable no-await-in-loop */
import {
  AdminTable,
  AdminTableState,
  dateFilterTemplate,
  dateTimeBody,
} from "components/Admin";
import {
  LogAuditBoolExp,
  LogAuditFieldsFragmentDoc,
} from "graphql/generated/graphqlRequest";
import { useDataAdapter } from "hooks/useDataAdapter";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";

export interface LogAuditTableProps {
  userId?: string;
  hideHeader?: boolean;
  tableHeight?: number;
  disableRowClick?: boolean;
}

const LogAuditTable: React.FC<LogAuditTableProps> = ({
  userId,
  hideHeader,
  tableHeight,
}) => {
  const toast = useRef<Toast>(null);

  const whereClause: LogAuditBoolExp | undefined = userId
    ? {
        userId: { _eq: userId },
      }
    : undefined;
  const { tableAdapter } = useDataAdapter(
    "logAudit",
    LogAuditFieldsFragmentDoc,
    whereClause
  );

  const initialFilters = {
    tableName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    operationKind: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: [
      "operation",
      "tableName",
    ],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
  });

  return (
    <div>
      <Toast ref={toast} />
      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-postComment text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Audit Log</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={setTableState}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        tableHeight={tableHeight}
        onRowSelect={(_event: DataTableSelectEvent) => {}}
      >
        <Column
          field="tableName"
          header="Table"
          filter
          sortable
        />
        <Column
          field="operationKind"
          header="Action"
          filter
          sortable
        />
        <Column
          field="createdAt"
          header="Created On"
          body={dateTimeBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default LogAuditTable;
