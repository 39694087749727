import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import { TabPanel, TabView } from "primereact/tabview";
import { Maybe, UserFieldsFragment, useUserByIdAdminQuery } from "graphql/generated/resourceApi";
import { useUserContext } from "shared/UserContext";
import GroupsTable from "components/Group/GroupsTable";
import PostsTable from "components/Post/PostsTable";
import UsersTable from "./UsersTable";
import BikesTable from "components/Bike/BikesTable";
import DevicesTable from "components/Device/DevicesTable";
import HistoryNotificationsTable from "components/User/HistoryNotificationsTable";
import UserDetail from "./UserDetail";
import LogAuditTable from "./LogAuditTable";

export const dialogWithTabsTableHeightOffset = 132;

export interface UserTabsProps {
  onUpdate: ({ title, message, user }: {title: string, message: string, user?: UserFieldsFragment}) => void;
  userId?: string;
  activeTab?:
    | "details"
    | "collectives"
    | "posts"
    | "blockedBy"
    | "garage"
    | "devices"
    | "notifications"
    | "activity";
    tableHeight?: number;
}

const tabs = {
  details: {
    tabLabel: "Details",
    tabIndex: 0,
  },
  collectives: {
    tabLabel: "Collectives",
    tabIndex: 1,
  },
  posts: {
    tabLabel: "Posts",
    tabIndex: 2,
  },
  blockedBy: {
    tabLabel: "Blocked By",
    tabIndex: 3,
  },
  garage: {
    tabLabel: "Garage",
    tabIndex: 4,
  },
  devices: {
    tabLabel: "Devices",
    tabIndex: 5,
  },
  notifications: {
    tabLabel: "Notifications",
    tabIndex: 6,
  },
  activity: {
    tabLabel: "Activity",
    tabIndex: 7,
  },
};

const UserTabs: React.FC<UserTabsProps> = ({ userId, activeTab, onUpdate, tableHeight }) => {
  const { client } = useUserContext();
  const userState = useUserByIdAdminQuery(client, { id: userId });
  const [user, setUser] = useState<Maybe<UserFieldsFragment>>();

  useEffect(() => {
    setUser(userState.data?.userById);
  }, [userState]);

  const wrappedOnUpdate = ({ title, message, user }: {title: string, message: string, user?: UserFieldsFragment}): void => {
    setUser(user);
    onUpdate({ title, message, user });
  };

  if (!userId) {
    return <></>;
  }

  if (!user) return <Spinner />;

  return (
    <div>
      <TabView
        style={{ padding: "0 1rem" }}
        activeIndex={activeTab ? tabs[activeTab].tabIndex : 0}
      >
        <TabPanel header={tabs.details.tabLabel}>
          <UserDetail user={user} onUpdate={wrappedOnUpdate} />
        </TabPanel>
        <TabPanel header={tabs.collectives.tabLabel}>
          <GroupsTable userId={user.id} hideHeader tableHeight={tableHeight} />
        </TabPanel>
        <TabPanel header={tabs.posts.tabLabel}>
          <PostsTable userId={user.id} hideHeader tableHeight={tableHeight} />
        </TabPanel>
        <TabPanel header={tabs.blockedBy.tabLabel}>
          <UsersTable
            blockedId={user.id}
            hideHeader
            tableHeight={tableHeight}
          />
        </TabPanel>
        <TabPanel header={tabs.garage.tabLabel}>
          <BikesTable
            disableRowButton
            userId={user.id}
            hideHeader
            tableHeight={tableHeight}
          />
        </TabPanel>
        <TabPanel header={tabs.devices.tabLabel}>
          <DevicesTable
            disableRowButton
            userId={user.id}
            hideHeader
            tableHeight={tableHeight}
          />
        </TabPanel>
        <TabPanel header={tabs.notifications.tabLabel}>
          <HistoryNotificationsTable
            userId={user.id}
            hideHeader
            tableHeight={tableHeight}
          />
        </TabPanel>
        <TabPanel header={tabs.activity.tabLabel}>
          <LogAuditTable
            userId={user.id}
            hideHeader
            tableHeight={tableHeight}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default UserTabs;
