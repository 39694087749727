import React from 'react';

import UsersTable from 'components/User/UsersTable';
import { useSearchParamsToTableState } from 'hooks/useSearchParamsToTableState';

export const UsersPage: React.FC = () => {
  const { tableState, updateSearchParams } = useSearchParamsToTableState();
  return <UsersTable tableStateOverride={tableState} onTableStateEvent={updateSearchParams} />;
};

export default UsersPage;
