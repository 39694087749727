import React, { useMemo, useState } from "react";
//import { ScalarComponentPropsBase } from "../../../shared/ScalarComponentPropsBase";
//import { useController } from "react-hook-form";
import { useAuthToken } from "shared/UserContext";
import "./ImageField.css";

export const ImageField: React.FC<{
  file?: { url?: string | null };
  unsavedImage?: File;
  setUnsavedImage?: (image: File | undefined) => void;
  setImageShouldBeDeleted?: (value: boolean) => void;
}> = ({ unsavedImage, setUnsavedImage, setImageShouldBeDeleted, file }) => {
  const [imageUrl, setImageUrl] = useState(file?.url);

  const token = useAuthToken();

  const imageSrc = useMemo(() => {
    if (unsavedImage) {
      return URL.createObjectURL(unsavedImage);
    }
    if (imageUrl) {
      return `${imageUrl}&token=${token}`;
    }
    return undefined;
  }, [unsavedImage, imageUrl]);

  window.addEventListener(
    "dragover",
    function (e) {
      e.preventDefault();
    },
    false
  );
  window.addEventListener(
    "drop",
    function (e) {
      e.preventDefault();
    },
    false
  );

  const onDragImage = (event: any) => {
    if (event.dataTransfer.files) {
      const image = event.dataTransfer.files[0];
      setUnsavedImage?.(image);
      setImageShouldBeDeleted?.(false);
    }
  };
  const onUpdateImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];
      setUnsavedImage?.(image);
      setImageShouldBeDeleted?.(false);
    }
  };
  const onDeleteImage = () => {
    setUnsavedImage?.(undefined);
    setImageShouldBeDeleted?.(true);
    setImageUrl(undefined);
  };
  return (
    <div>
      <label
        htmlFor={"ff-image-input-field"}
        style={{ marginLeft: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
      >
        Image
      </label>
      <input
        type="file"
        id="edit-collective-image"
        accept="image/*"
        className="hidden"
        onChange={onUpdateImage}
      />
      {imageSrc ? (
        <div
          className="image-input-wraper rounded"
          style={{
            width: "280px",
            height: "280px",
          }}
        >
          <img
            src={imageSrc}
            alt="Collective"
            style={{
              width: "280px",
              height: "280px",
            }}
            className="rounded"
          />
          <div
            className="hover-container rounded flex-row justify-center items-center gap-4"
            style={{
              width: "280px",
              height: "280px",
              maxWidth: "none",
            }}
          >
            <label htmlFor="edit-collective-image" className="cursor-pointer">
              <i className="pi pi-pencil text-2xl text-white" />
            </label>
            {!!setImageShouldBeDeleted && (
              <button onClick={onDeleteImage} type="button">
                <i className="pi pi-trash text-2xl text-white" />
              </button>
            )}
          </div>
        </div>
      ) : (
        <label
          htmlFor="edit-collective-image"
          className="cursor-pointer"
          style={{
            height: "200px",
          }}
        >
          <div
            className="image-input-wraper border-2 border-dashed rounded box-border flex flex-column justify-center items-center gap-1"
            style={{
              height: "200px",
            }}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDragEnter={(e) => {
              e.preventDefault();
            }}
            onDragLeave={(e) => {
              e.preventDefault();
            }}
            onDrop={onDragImage}
          >
            <i className="pi pi-image text-7xl text-gray-500" />
            <div className="ext-xl text-gray-500">
              Choose an Image or Drag it here
            </div>
          </div>
        </label>
      )}
    </div>
  );
};
