import React, { useState, useEffect } from 'react'
import { Image, ImageProps } from 'primereact/image'
import { useUserContext } from 'shared/UserContext'

interface File {
  url: string
  [key: string]: any
}

export type FileImageProps = ImageProps & { file?: File }

export const FileImage: React.FC<FileImageProps> = ({ file, ...rest }) => {
  const { token } = useUserContext()
  const [url, setUrl] = useState<string | undefined>()

  useEffect(() => {
    const setupUrl = async (): Promise<void> => {
      setUrl(file ? `${file.url}&token=${token}` : undefined)
    }
    setupUrl()
  }, [])

  return <Image {...rest} src={url} />
}
