import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import {
  GroupFieldsFragment,
  GroupInsertInput,
} from "graphql/generated/resourceApi";
import { AdminForm } from "components/Admin/AdminForm/AdminForm";
import { useUserContext } from "../../shared/UserContext";
import AdminInputText from "components/Admin/AdminForm/AdminFormInputs/AdminInputText";
import { LocationField } from "../LocationForm/LocationField";
import useFileApi from "hooks/useUploadImage";
import { Location } from "components/LocationForm/LocationForm";
import { AdminInputBoolean } from "components/Admin";
import { GroupSetInput } from "graphql/generated/graphqlRequest";
import { Toast } from "primereact/toast";

interface IGroupDetailFormProps extends React.ComponentProps<"form"> {
  group?: GroupFieldsFragment;
  onComplete: (group?: GroupFieldsFragment) => void;
}

const GroupDetailForm: React.FC<IGroupDetailFormProps> = ({
  group,
  onComplete,
}) => {
  const toastRef = useRef<Toast>(null);
  const { sdkClient } = useUserContext();
  const { uploadImage } = useFileApi();
  const { token: authToken } = useUserContext();
  const [loading, setLoading] = useState(false);

  const [location, setLocation] = useState<Location | undefined>(() => {
    const location = group?.location;
    return (
      (location && {
        name: location.name ?? undefined,
        latitude: location.latitude,
        longitude: location.longitude,
      }) ||
      undefined
    );
  });
  const [unsavedImage, _setUnsavedImage] = useState<File>();

  const handleSave = async (data: Record<string, any>) => {
    setLoading(true);
    const input: GroupSetInput | GroupInsertInput = {
      name: data.name,
      description: data.description,
      isHidden: data.isHidden,
    };
    if (unsavedImage) {
      const result = await uploadImage(unsavedImage, authToken, {
        //postId: data.insert_bike_one?.id,
        isPublic: true,
      });
      if (result) {
        input.imageId = result.id;
      }
    }
    try {
      let updatedGroup: GroupFieldsFragment | undefined;
      if (group) {
        const updateResult = await sdkClient.updateGroup({
          id: group.id,
          object: input,
          attributes: [],
        });
        updatedGroup = updateResult.updateGroup ?? undefined;
      } else {
        const insertResult = await sdkClient.insertGroup({ object: input });
        updatedGroup = insertResult.insertGroup ?? undefined;
      }
      if (group?.location && location) {
        await sdkClient.updateGroupLocation({
          id: group.location.id,
          object: {
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
          },
        });
      } else if (location) {
        await sdkClient.insertGroupLocation({
          object: {
            groupId: updatedGroup?.id,
            latitude: location.latitude,
            longitude: location.longitude,
            name: location.name,
          },
        });
      }
      if (updatedGroup?.id) {
        const result = await sdkClient.groupById({ id: updatedGroup.id });
        updatedGroup = result.groupById ?? undefined;
        if (updatedGroup) {
          toastRef.current?.show({
            severity: "success",
            summary: "Group Updated!",
          });
          onComplete(updatedGroup);
          setLoading(false);
          return;
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
    toastRef.current?.show({
      severity: "error",
      summary: "Something Went Wrong",
    });
  };

  return (
    <>
      <Toast ref={toastRef} />
      <>
        <div className="w-full">
          <div className="mt-7 flex flex-row">
            <i
              className="pi pi-shopping-bag mr-4"
              style={{ fontSize: "1.5em", color: "grey" }}
            />
            <div className="w-full">
              <AdminForm
                className="flex flex-col gap-4"
                defaultValues={group}
                onSubmit={handleSave}
              >
                <AdminInputText name="name" required label="Name" />
                <AdminInputText
                  name="description"
                  required
                  label="Description"
                />
                <AdminInputBoolean
                  name="isHidden"
                  label="Hidden"
                  checked={group?.isHidden ?? false}
                />
                <LocationField location={location} setLocation={setLocation} />
                <Button
                  label={group ? "Save Group " : "Create Group"}
                  className="p-button-primary"
                  type="submit"
                  loading={loading}
                />
              </AdminForm>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default GroupDetailForm;
