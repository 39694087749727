/* eslint-disable no-await-in-loop */
import React, { ReactNode, useEffect, useRef, useState } from "react";

import {
  AdminTable,
  AdminTableState,
  booleanBody,
  booleanFilterTemplate,
  dateBody,
  dateFilterTemplate,
  getInitial,
  imageBody,
} from "components/Admin";
import UserTabs, {
  dialogWithTabsTableHeightOffset,
} from "components/User/UserTabs";
import { UserAdminTableFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useDataAdapter } from "hooks/useDataAdapter";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import {
  Column,
  ColumnBodyOptions,
  ColumnFilterElementTemplateOptions,
} from "primereact/column";
import {
  DataTableFilterMeta,
  DataTableSelectEvent,
} from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

import { valueForPath } from "components/Admin/AdminTable/adminTableUtils";
import { UserFieldsFragment } from "graphql/generated/resourceApi";
import { Button } from "primereact/button";
import { useAuthToken } from "shared/UserContext";
import {
  formatRidingSince,
  userCollectiveName,
  userRoleOptions,
} from "utils/userUtils";
import UserEdit from "./UserEdit";

export const devicesBody = (data: any, options: ColumnBodyOptions) => {
  const devices = valueForPath(options.field.split("."), data);
  const deviceLabels: string[] = [];
  const iosCount = devices.filter((device: any) => device.os === "ios").length;
  const androidCount = devices.filter(
    (device: any) => device.os === "android"
  ).length;
  if (iosCount) {
    deviceLabels.push(`iOS: ${iosCount}`);
  }
  if (androidCount) {
    deviceLabels.push(`Android: ${androidCount}`);
  }
  return deviceLabels.join(", ") || "No Info";
};

export const yearsRidingBody = (
  data: any,
  options: ColumnBodyOptions
): string => {
  const ridingSince = valueForPath(options.field.split("."), data);
  return formatRidingSince(ridingSince);
};

export const roleFilterElement = (
  options: ColumnFilterElementTemplateOptions
): ReactNode => (
  <Dropdown
    value={options.value}
    options={userRoleOptions}
    onChange={(e) => options.filterCallback(e.value, options.index)}
    placeholder="Select a Role"
    className="p-column-filter"
    showClear
  />
);

export interface UsersTableProps {
  groupId?: string;
  blockedId?: string;
  tableStateOverride?: Partial<AdminTableState>;
  onTableStateEvent?: (e: AdminTableState) => void;
  hideHeader?: boolean;
  tableHeight?: number;
}

const UsersTable: React.FC<UsersTableProps> = ({
  groupId,
  blockedId,
  tableStateOverride,
  onTableStateEvent,
  hideHeader,
  tableHeight,
}) => {
  const dialogRef = useRef<Dialog>(null);
  const [dialogHeight, setDialogHeight] = useState(0);
  const toast = useRef<Toast>(null);
  const token = useAuthToken();
  const { tableAdapter } = useDataAdapter(
    "user",
    UserAdminTableFieldsFragmentDoc,
    groupId
      ? { userGroups: { groupId: { _eq: groupId } } }
      : blockedId
        ? { blockedUsers: { blockedUserId: { _eq: blockedId } } }
        : undefined
  );

  const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
  const [createUserModalVisible, setCreateUserModalVisible] =
    useState<boolean>(false);
  const [userId, setUserId] = useState<string>();

  const initialFilters: DataTableFilterMeta = {
    primaryEmail: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    firstName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    lastName: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    postsCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    groupsCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    blocksCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    bikesCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    "homeLocation.name": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "attributes.hasuraRole": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "attributes.pronouns": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "attributes.phoneNumber": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "attributes.website": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    "attributes.instagramLink": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    primaryEmailIsVerified: { value: null, matchMode: FilterMatchMode.EQUALS },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["primaryEmail", "name"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
    ...tableStateOverride,
  });
  const handleOnStateEvent = (state: AdminTableState) => {
    setTableState(state);
    onTableStateEvent?.(state);
  };
  useEffect(() => {
    setTableState((previousTableState) => ({
      ...previousTableState,
      ...tableStateOverride,
    }));
  }, [tableStateOverride]);

  const getInitials = (data: any): string => {
    return (
      `${getInitial(data.firstName)}${getInitial(data.lastName)}` ??
      getInitial(data.primaryEmail)
    );
  };

  const onUpdate = ({
    title,
    message,
  }: {
    title: string;
    message: string;
    user?: UserFieldsFragment;
  }): void => {
    tableAdapter.reload();
    toast.current?.show({
      severity: "success",
      summary: title,
      detail: message,
      sticky: true,
      // life: 10000,
      closable: true,
    });
    setUserModalVisible(false);
    setCreateUserModalVisible(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={userModalVisible}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setUserModalVisible(false)}
        onResize={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
        onShow={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
      >
        <UserTabs
          userId={userId}
          onUpdate={onUpdate}
          tableHeight={dialogHeight - dialogWithTabsTableHeightOffset}
        />
      </Dialog>

      <Dialog
        visible={createUserModalVisible}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setCreateUserModalVisible(false)}
        header="Create User"
      >
        <UserEdit onUpdate={onUpdate} />
      </Dialog>

      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-users text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Users</h1>
          </div>
          <div>
            <Button
              className="my-4 p-background-primary-color"
              type="button"
              label="Create User"
              icon="pi pi-plus"
              onClick={() => setCreateUserModalVisible(true)}
            />
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={handleOnStateEvent}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search user email or name"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        onRowSelect={(e: DataTableSelectEvent) => {
          setUserId(e.data.id);
          setUserModalVisible(true);
        }}
        tableHeight={tableHeight}
      >
        <Column
          field="profileImage.url"
          header="Avatar"
          body={imageBody(token, getInitials)}
        />
        <Column
          field="primaryEmail"
          header="Email"
          sortable
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="firstName"
          header="First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="lastName"
          header="Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="attributes.hasuraRole"
          header="Role"
          filterElement={roleFilterElement}
          filter
        />
        <Column
          field="postsCount"
          header="Posts"
          dataType="numeric"
          filterType="number"
          filter
        />
        <Column
          field="groupsCount"
          header="Groups"
          dataType="numeric"
          filterType="number"
          filter
        />
        <Column
          field="blocksCount"
          header="Blocked By"
          dataType="numeric"
          filterType="number"
          filter
        />
        <Column
          field="bikesCount"
          header="Bikes"
          dataType="numeric"
          filterType="number"
          filter
        />
        <Column
          field="homeLocation.name"
          header="Location"
          style={{
            minWidth: "200px",
          }}
          sortable
          filter
        />
        <Column
          field="collective"
          header="Collective"
          style={{
            minWidth: "200px",
          }}
          body={(row) => {
            return userCollectiveName(row) || "None";
          }}
        />
        <Column field="attributes.pronouns" header="Pronouns" filter />
        <Column
          field="attributes.phoneNumber"
          header="Phone Number"
          style={{
            minWidth: "200px",
          }}
          filter
        />
        <Column
          field="attributes.dateOfBirth"
          header="Birth Date"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
        />
        <Column
          field="attributes.ridingSince"
          header="Years Riding"
          body={yearsRidingBody}
        />
        <Column
          field="attributes.website"
          header="Website"
          style={{
            minWidth: "200px",
          }}
          filter
        />
        <Column
          field="attributes.instagramLink"
          header="IG Handle"
          style={{
            minWidth: "150px",
          }}
          filter
        />
        <Column field="devices" header="Devices" body={devicesBody} />
        <Column
          field="attributes.lastActivity"
          header="Last Activity"
          body={dateBody}
          dataType="date"
        />
        <Column
          field="primaryEmailIsVerified"
          header="Email Verified"
          sortable
          body={booleanBody}
          dataType="boolean"
          filter
          filterElement={booleanFilterTemplate}
        />
        <Column
          field="createdAt"
          header="Registered On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default UsersTable;
