import React from "react";
import DevicesTable from "components/Device/DevicesTable";
import { useSearchParamsToTableState } from "hooks/useSearchParamsToTableState";

export const DevicesPage: React.FC = () => {
  const { tableState, updateSearchParams } = useSearchParamsToTableState();
  return <DevicesTable tableStateOverride={tableState} onTableStateEvent={updateSearchParams} />;
};

export default DevicesPage;
