import { useEffect, useState } from "react";
import {
  DataTableFilterMeta,
} from "primereact/datatable";
import { useSearchParams } from "react-router-dom";
import { AdminTableState } from "components/Admin";

function tableStateToParams(
  e?: Partial<AdminTableState>
): URLSearchParams {
  const newParams: Record<string, string> = {};
  if (typeof e?.page === "number") {
    newParams.page = (e.page + 1).toString();
  } else {
    newParams.page = "1";
  }
  

  if (e?.sortField) {
    newParams.sortField = e.sortField;
  }
  if (typeof e?.sortOrder === "number") {
    newParams.sortOrder = e.sortOrder.toString();
  }
  newParams.globalFilter = e?.globalFilter ?? "";
  newParams.filters = "";
  if (e?.filters && Object.keys(e.filters).length) {
    const filtersWithValues: DataTableFilterMeta = {};
    Object.entries(e.filters).forEach(([key, filterMeta]) => {
      if (key === "global") {
        return;
      } 
      if ("value" in filterMeta && filterMeta.value !== null) {
        filtersWithValues[key] = filterMeta;
      }
      if ("constraints" in filterMeta) {
        const constraintsWithValues = filterMeta.constraints.filter(
          (constraint) => constraint.value != null
        );
        if (constraintsWithValues.length) {
          filtersWithValues[key] = {
            operator: filterMeta.operator,
            constraints: constraintsWithValues,
          };
        }
      }
    });
    if (Object.keys(filtersWithValues).length) {
      const stringifiedFilters = JSON.stringify(filtersWithValues);
      if (stringifiedFilters) {
        newParams.filters = stringifiedFilters;
      }
    }
  }

  return new URLSearchParams({
    ...newParams,
  });
}

function paramsToTableState(
  searchParams: URLSearchParams,
): Partial<AdminTableState> {
  const currentParams = searchParams
    ? Object.fromEntries([...searchParams])
    : {};
  const newTableState: Partial<AdminTableState> = {};

  if (currentParams.page) {
    newTableState.page = Number(currentParams.page) - 1;
  }
  try {
    newTableState.filters = JSON.parse(currentParams.filters);
  } catch(error) {}
  if (currentParams.sortOrder) {
    newTableState.sortOrder = Number(currentParams.sortOrder) as any;
  }
  if (currentParams.sortField) {
    newTableState.sortField = currentParams.sortField
  }
  if ("globalFilter" in currentParams) {
    newTableState.globalFilter = currentParams.globalFilter ?? "";
  }

  return newTableState;
}

export function useSearchParamsToTableState(): {
  tableState: Partial<AdminTableState>;
  updateSearchParams: (
    e?: Partial<AdminTableState>,
  ) => void;
} {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tableState, setTableState] = useState<Partial<AdminTableState>>(paramsToTableState(searchParams));

  const updateSearchParams = (e?: Partial<AdminTableState>) => {
    const newParams = tableStateToParams(e);
    if (newParams.toString() !== searchParams.toString()) {
      setSearchParams(newParams);
    }
  };

  useEffect(() => {
    setTableState(paramsToTableState(searchParams));
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.toString() === "" && Object.keys(tableState).length > 0) {
      updateSearchParams(tableState);
    }
  }, [tableState, searchParams, updateSearchParams]);

  return { tableState, updateSearchParams };
}
