import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Sidebar } from "primereact/sidebar";
import { Toolbar } from "primereact/toolbar";
import { Link, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import { useUserContext } from "shared/UserContext";
import logoLight from "assets/images/litas_script_white.png";
import { ThemeSwitch } from "../ThemeSwitch/ThemeSwitch";
import { getThemePropertyValue } from "../../utils/helpers";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import paths from "shared/routes/paths";
import { buildDate } from "buildDateGenerated";
import useLayoutDimensions from "hooks/useLayoutDimensions";
import Spinner from "components/Spinner";
import log from "log";
import { Card } from "primereact/card";

const { VITE_APP_SHOW_DEV_BANNER, VITE_APP_VERSION: version } = import.meta.env;

const showDev = VITE_APP_SHOW_DEV_BANNER === "true";

export const ProtectedLayout: React.FC = () => {
  const auth = useAuth();
  const { logout, token, userRole } = useUserContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const topNav = searchParams.get("top_nav");

  const [logoutConfirmVisible, setLogoutConfirmVisible] =
    useState<boolean>(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [logo] = useState(logoLight);
  const {
    toolbarHeight,
    sidebarWidth,
    outletPadding,
    outletWidth,
    outletHeight,
    isDesktop,
  } = useLayoutDimensions();

  const callConfirm = (): void => {
    setLogoutConfirmVisible(true);
  };
  const onCloseConfirm = (): void => {
    setLogoutConfirmVisible(false);
  };
  const onLogoutCloseConfirm = (): void => {
    setLogoutConfirmVisible(false);
    logout();
  };

  const leftContents = (
    <div className="flex flex-row gap-3 items-center">
      {isDesktop || (
        <Button
          onClick={() => setSidebarVisible(true)}
          icon="pi pi-bars"
          className="p-button-text"
        />
      )}
      <Link to="/">
        <Image
          className="principal-logo-header "
          src={logo}
          alt="Logo"
          imageStyle={{
            height: "2.25rem",
            objectFit: "fill",
          }}
        />
      </Link>
    </div>
  );

  const rightContents = (
    <>
      <ThemeSwitch />
      <Button
        label="Logout"
        icon="pi pi-sign-out"
        className="p-primary-text border-0 bg-transparent ml-4 hover-bg-dark-gray"
        onClick={callConfirm}
      />
    </>
  );

  const items: Array<MenuItem> = [
    {
      label: "Dashboard",
      icon: "pi pi-fw pi-chart-line",
      command: () => {
        navigate(paths.general.home);
        setSidebarVisible(false);
      },
    },
    {
      label: "Users",
      icon: "pi pi-fw pi-user",
      command: () => {
        navigate(paths.users.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Collectives",
      icon: "pi pi-fw pi-users",
      command: () => {
        navigate(paths.groups.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Collective Requests",
      icon: "pi pi-fw pi-users",
      command: () => {
        navigate(paths.groupRequests.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Posts",
      icon: "pi pi-fw pi-book",
      command: () => {
        navigate(paths.posts.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Home Collective Posts",
      icon: "pi pi-fw pi-home",
      command: () => {
        navigate(paths.homePosts.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Pinned Posts",
      icon: "pi pi-fw pi-thumbtack",
      command: () => {
        navigate(paths.pinnedPosts.root);
        setSidebarVisible(false);
      },
    },
    // {
    //   label: "Reported Posts",
    //   icon: "pi pi-fw pi-exclamation-circle",
    //   command: () => {
    //     navigate(paths.reportedPosts.root);
    //     setSidebarVisible(false);
    //   },
    // },
    {
      label: "Post Reports",
      icon: "pi pi-fw pi-exclamation-circle",
      command: () => {
        navigate(paths.postReports.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Hidden Posts",
      icon: "pi pi-fw pi-eye-slash",
      command: () => {
        navigate(paths.hiddenPosts.root);
        setSidebarVisible(false);
      },
    },
    // {
    //   label: "Featurebase Test",
    //   icon: "pi pi-fw pi-exclamation-circle",
    //   command: () => {
    //     navigate(paths.featurebaseTest.root);
    //   },
    // },
    {
      label: "Garage",
      icon: "pi pi-fw pi-wrench",
      command: () => {
        navigate(paths.bikes.root);
        setSidebarVisible(false);
      },
    },
    {
      label: "Devices",
      icon: "pi pi-fw pi-mobile",
      command: () => {
        navigate(paths.devices.root);
        setSidebarVisible(false);
      },
    },
  ];

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      auth.signinRedirect().catch((error) => {
        log.debug(error);
      });
    }
  }, [auth]);

  if (!token) {
    return <Spinner text="Loading..." />;
  }

  if (userRole !== "admin") {
    return (
      <div className="loader">
        <Card>
        <div className="p-text-primary mb-10">
        Access Denied
        </div>
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          onClick={logout}
        />
        </Card>
        
      </div>
    );
  }

  return (
    <>
      <Toolbar
        left={leftContents}
        right={rightContents}
        style={{
          position: "fixed",
          top: "0",
          width: "100vw",
          height: toolbarHeight,
          zIndex: 1000,
          borderRadius: 0,
          border: "none",
          padding: "0 1.7rem",
          backgroundColor: getThemePropertyValue("--header-background"),
        }}
      />

      <ConfirmDialog
        visible={logoutConfirmVisible}
        onHide={onCloseConfirm}
        message="You want to Sign out?"
        header="Are you sure?"
        icon="pi pi-sign-out"
        accept={onLogoutCloseConfirm}
        reject={onCloseConfirm}
        acceptLabel="Yes"
        rejectLabel="No"
      />
      {topNav !== "false" && isDesktop ? (
        <div
          style={{
            width: sidebarWidth,
            paddingTop: "32px",
            paddingBottom: "32px",
            overflowY: "auto",
          }}
          className="p-surface-header fixed h-full justify-between flex flex-col"
        >
          <div>
            <Menu className="w-full" style={{ border: 0 }} model={items} />
            <div className="mt-16 px-4">
              <p className="p-text-primary text-center">
                Version: {`${version} (${buildDate})`}
              </p>
            </div>
          </div>
          {showDev && (
            <div
              className="relative"
              style={{
                width: "240px",
                height: "240px",
              }}
            >
              <div
                className="w-0 h-0 border-solid border-b-red-100 border-l-red-100 border-t-transparent border-r-transparent absolute top-0 left-0"
                style={{
                  borderWidth: "240px 0 0 240px",
                  //borderColor: "transparent transparent transparent #007bff",
                }}
              />
              <h1 className="rotate-45 absolute text-red-600 top-28 -left-4 text-3xl font-bold">
                Dev Environment
              </h1>
              <div
                className="w-0 h-0 border-solid p-triangle-bottom-left absolute bottom-0 left-0"
                style={{
                  borderWidth: "160px 0 0 160px",
                  //borderColor: "transparent transparent transparent #007bff",
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <Sidebar
          style={{ overflowY: "auto", flex: 1 }}
          visible={sidebarVisible}
          onHide={() => setSidebarVisible(false)}
          modal
          appendTo="self"
        >
          <Menu className="w-full h-full" style={{ border: 0 }} model={items} />
        </Sidebar>
      )}
      <div
        className="p-surface-content h-full"
        style={{
          marginLeft: sidebarWidth,
          marginTop: toolbarHeight,
          padding: outletPadding,
          maxHeight: outletHeight,
          maxWidth: outletWidth,
        }}
      >
        <Outlet />
      </div>
    </>
  );
};
