import React from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useNavigate, useParams } from "react-router-dom";
import BikeTabs from "components/Bike/BikeTabs";
import { useBikeByIdQuery } from "graphql/generated/resourceApi";
import { useUserContext } from "shared/UserContext";
import Spinner from "components/Spinner";

const Bike: React.FC = () => {
  const { id: bikeId } = useParams();
  const { client } = useUserContext();
  const bikeState = useBikeByIdQuery(client, { id: bikeId });
  const bike = bikeState.data?.bikeById;

  const navigate = useNavigate();
  const redirectbikes = (): void => {
    navigate(-1);
  };

  if (!bikeId) {
    redirectbikes();
    return <></>;
  }

  if (!bike) return <Spinner />;

  return (
    <Card
      header={
        <Button
          onClick={redirectbikes}
          className="p-button-text p-button-rounded m-2"
          icon="pi pi-chevron-left"
        />
      }
    >
      <BikeTabs bike={bike} bikeState={bikeState} />
    </Card>
  );
};

export default Bike;
