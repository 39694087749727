import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "pages/Dashboard";
import UsersPage from "pages/Users/UsersPage";
import {ProtectedLayout} from "components/Layout/ProtectedLayout";

import paths from "./paths";
import GroupsPage from "pages/Groups/GroupsPage";
import PostsPage from "pages/Posts/PostsPage";
import BikesPage from "pages/Bikes/BikesPage";
import BikePage from "pages/Bikes/BikePage";
import DevicesPage from "pages/Devices/DevicesPage";
import MapPage from "pages/MapPage";
import PostReportsTable from "components/Post/PostReportsTable";
import GroupRequestsPage from "pages/Groups/GroupRequestsPage";

export const formShareRoutePath = "f";

const AppRouter: React.FC = () => (
  <Routes>
    <Route path="map" element={<MapPage />}/>
    <Route element={<ProtectedLayout />}>
      <Route path={paths.general.home} element={<Dashboard />} />
      <Route path={paths.users.root} element={<UsersPage />} />
      <Route path={paths.groups.root} element={<GroupsPage />} />
      <Route path={paths.groupRequests.root} element={<GroupRequestsPage />} />
      <Route path={paths.posts.root} element={<PostsPage />} />
      <Route path={paths.homePosts.root} element={<PostsPage filterType="home" />} />
      <Route path={paths.pinnedPosts.root} element={<PostsPage filterType="pinned" />} />
      <Route path={paths.reportedPosts.root} element={<PostsPage filterType="reported" />} />
      <Route path={paths.postReports.root} element={<PostReportsTable />} />
      <Route path={paths.hiddenPosts.root} element={<PostsPage filterType="hidden" />} />
      <Route path={paths.bikes.root} element={<BikesPage />} />
      <Route path={paths.devices.root} element={<DevicesPage />} />
      <Route path={paths.bikes.detail} element={<BikePage />} />
      <Route path={paths.root} element={<Dashboard />} />
    </Route>
  </Routes>
);

export default AppRouter;
