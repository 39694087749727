/* eslint-disable no-await-in-loop */
import React, { useState, useRef, ReactElement, useEffect } from "react";
import {
  AdminTable,
  AdminTableState,
  booleanBody,
  booleanFilterTemplate,
  dateBody,
  dateFilterTemplate,
  imageSize,
} from "components/Admin";
import { Column } from "primereact/column";
import {
  DataTableFilterMeta,
  DataTableSelectEvent,
} from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import {
  PostBoolExp,
  type PostAdminTableFieldsFragment,
} from "graphql/generated/resourceApi";
import { PostAdminTableFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useAuthToken } from "shared/UserContext";
import { useDataAdapter } from "hooks/useDataAdapter";
import { Image } from "primereact/image";
import PostTabs from "components/Post/PostTabs";
import { roleFilterElement, yearsRidingBody } from "components/User/UsersTable";
import { dialogWithTabsTableHeightOffset } from "components/User/UserTabs";
import { formatTextWithMentions } from "utils/postUtils";
import { Checkbox } from "primereact/checkbox";

export type PostUpdate = {}
export type PostFilterType = "none" | "home" | "pinned" | "reported" | "hidden";

export interface PostsTableProps {
  groupId?: string;
  userId?: string;
  filterType?: PostFilterType;
  tableStateOverride?: Partial<AdminTableState>;
  onTableStateEvent?: (e: AdminTableState) => void;
  hideHeader?: boolean;
  tableHeight?: number;
}

const PostsTable: React.FC<PostsTableProps> = ({
  groupId,
  userId,
  filterType,
  tableStateOverride,
  onTableStateEvent,
  hideHeader,
  tableHeight,
}) => {
  const [onlyTheLitasPosts, setOnlyTheLitasPosts] = useState<boolean>(false);
  const dialogRef = useRef<Dialog>(null);
  const [dialogHeight, setDialogHeight] = useState(0);
  const toast = useRef<Toast>(null);
  const token = useAuthToken();
  const baseWhere: PostBoolExp = userId ? { userId: { _eq: userId } } : {};
  if (groupId) {
    baseWhere.groupId = { _eq: groupId };
  }
  switch (filterType) {
    case "home":
      baseWhere.groupId = { _isNull: true };
      break;
    case "pinned":
      baseWhere.pinnedById = { _isNull: false };
      break;
    case "reported":
      baseWhere.userPostReports = { report: { _isNull: false } };
      break;
    case "hidden":
      baseWhere._or = [
        { isHiddenByReport: { _eq: true } },
        { isSilentlyHidden: { _eq: true } },
      ];
      break;
  }

  const { tableAdapter } = useDataAdapter(
    "post",
    PostAdminTableFieldsFragmentDoc,
    baseWhere
  );

  useEffect(() => {
    tableAdapter.reload();
  }, [filterType]);

  const [showPostModal, setShowPostModal] = useState<boolean>(false);
  const [currentPost, setCurrentPost] = useState<
    PostAdminTableFieldsFragment | undefined
  >();

  const initialFilters: DataTableFilterMeta = {
    body: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    commentCount: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    "group.name": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.firstName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.lastName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isSilentlyHidden: { value: null, matchMode: FilterMatchMode.EQUALS },
    isHiddenByReport: { value: null, matchMode: FilterMatchMode.EQUALS },
    "pinnedBy.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    pinnedAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["body", "user.name"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
    ...tableStateOverride,
  });
  const handleOnStateEvent = (state: AdminTableState) => {
    setTableState(state);
    onTableStateEvent?.(state);
  };
  useEffect(() => {
    setTableState((previousTableState) => ({
      ...previousTableState,
      ...tableStateOverride,
    }));
  }, [tableStateOverride]);

  const onUpdate = ({
    title,
    message,
  }: {
    title: string;
    message: string;
    post?: PostAdminTableFieldsFragment;
  }): void => {
    tableAdapter.reload();
    toast.current?.show({
      severity: "success",
      summary: title,
      detail: message,
      sticky: true,
      // life: 10000,
      closable: true,
    });
    setShowPostModal(false);
  };

  const imageCell = (row: any): ReactElement => {
    const url = row?.postImages[0]?.file
      ? `${row.postImages[0].file.url}&token=${token}`
      : undefined;

    if (url) {
      return <Image src={url} style={{ width: imageSize }} />;
    }
    return <></>;
  };

  let headerTitle = `Posts`;
  if (filterType === "reported") {
    headerTitle = "Reported & Flagged Posts";
  } else if (filterType === "pinned") {
    headerTitle = "Pinned Posts";
  }
  let headerIcon = "pi-book";
  if (filterType === "reported") {
    headerIcon = "pi-exclamation-circle";
  }
  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={showPostModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowPostModal(false)}
        ref={dialogRef}
        onResize={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
        onShow={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
      >
        <PostTabs
          post={currentPost!}
          onUpdate={onUpdate}
          tableHeight={dialogHeight - dialogWithTabsTableHeightOffset}
        />
      </Dialog>

      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className={`pi ${headerIcon} text-2xl mr-3`} />
            <h1 className="text-3xl font-bold m-4">{headerTitle}</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={handleOnStateEvent}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search post body or user name"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        onRowSelect={(e: DataTableSelectEvent) => {
          setCurrentPost(e.data);
          setShowPostModal(true);
        }}
        tableHeight={tableHeight}
        defaultFieldsSelected={[
          "image",
          "body",
          "group.name",
          "isSilentlyHidden",
          "isHiddenByReport",
          "commentCount",
          "userPostReactionsAggregate.aggregate.count",
          "userPostReportsAggregate.aggregate.count",
          "user.primaryEmail",
          "user.firstName",
          "user.lastName",
          "pinnedBy.primaryEmail",
          "pinnedAt",
          "createdAt",
        ]}
        header={
          !hideHeader && (
            <div className="flex flex-row items-center mt-2 p-text-primary">
              <Checkbox
                onChange={(event) => {
                  setOnlyTheLitasPosts(event.checked ?? false);
                }}
                checked={onlyTheLitasPosts}
              />
              <div className="ml-2">The Litas Collective Posts Only</div>
            </div>
          )
        }
      >
        <Column
          field="image"
          header="Image"
          body={imageCell}
          style={{ minWidth: imageSize + 24 }}
        />
        <Column
          field="body"
          header="Body"
          sortable
          filter
          style={{
            minWidth: "400px",
            maxWidth: "600px",
            overflow: "hidden",
          }}
          body={(row) => formatTextWithMentions(row.body)}
        />

        <Column
          field="group.name"
          header="Collective"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="isSilentlyHidden"
          header="Shadow Banned"
          sortable
          body={booleanBody}
          dataType="boolean"
          filter
          filterElement={booleanFilterTemplate}
        />
        <Column
          field="isHiddenByReport"
          header="Hidden"
          sortable
          body={booleanBody}
          dataType="boolean"
          filter
          filterElement={booleanFilterTemplate}
        />
        <Column
          field="commentCount"
          header="Comments"
          dataType="numeric"
          filterType="number"
          filter
        />
        <Column
          field="userPostReactionsAggregate.aggregate.count"
          header="Reactions"
        />
        <Column
          field="userPostReportsAggregate.aggregate.count"
          header="Reports"
        />
        <Column
          field="user.primaryEmail"
          header="Email"
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="user.firstName"
          header="First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.lastName"
          header="Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.attributes.hasuraRole"
          header="Role"
          filterElement={roleFilterElement}
          // filter
        />
        <Column
          field="user.postsCount"
          header="Posts"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.groupsCount"
          header="Groups"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.blocksCount"
          header="Blocked By"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.bikesCount"
          header="Bikes"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.homeLocation.name"
          header="Location"
          style={{
            minWidth: "200px",
          }}
          sortable
          // filter
        />
        <Column
          field="user.attributes.pronouns"
          header="Pronouns"
          // filter
        />
        <Column
          field="user.attributes.phoneNumber"
          header="Phone Number"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.dateOfBirth"
          header="Birth Date"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
        />
        <Column
          field="user.attributes.ridingSince"
          header="Years Riding"
          body={yearsRidingBody}
        />
        <Column
          field="user.attributes.website"
          header="Website"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.lastActivity"
          header="Last Activity"
          body={dateBody}
          dataType="date"
        />
        <Column
          field="user.attributes.instagramLink"
          header="IG Handle"
          style={{
            minWidth: "150px",
          }}
          filter
        />
        <Column
          field="pinnedBy.primaryEmail"
          header="Pinned By"
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="pinnedAt"
          header="Pinned On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
        <Column
          field="createdAt"
          header="Posted On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default PostsTable;
