import React from "react";

import PostsTable, { PostFilterType } from "components/Post/PostsTable";
import { useSearchParamsToTableState } from "hooks/useSearchParamsToTableState";

export interface PostsPageProps {
  filterType?: PostFilterType
}

export const PostsPage: React.FC<PostsPageProps> = ({filterType}) => {
  const { tableState, updateSearchParams } = useSearchParamsToTableState();
  console.log('filterType', filterType);
  return <PostsTable filterType={filterType} tableStateOverride={tableState} onTableStateEvent={updateSearchParams} />;
};

export default PostsPage;
