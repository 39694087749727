import { useFileByIdQuery } from "graphql/generated/resourceApi";
import { useClient } from "shared/UserContext";
import { FileFieldsFragment } from "graphql/generated/graphqlRequest";
import { useState } from "react";

const baseUrl = import.meta.env.VITE_APP_FILE_URL;

export function fileToImage(file: File): Promise<HTMLImageElement> {
  const url = URL.createObjectURL(file);
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = url;
  });
}

const useFileApi = () => {
  const client = useClient();
  const [fileId, setFileId] = useState<FileFieldsFragment>();
  const { data: fileData } = useFileByIdQuery(client, { id: fileId }, { enabled: !!fileId });

  const uploadImage = async (
    file: File,
    token: string,
    queryParams?: any
  ): Promise<{ id: string } | undefined> => {
    const image = await fileToImage(file);
    if (image.width && image.height && file.type) {
      const meta = {
        image: {
          width: image.width,
          height: image.height,
          aspectRatio: image.width / image.height,
        },
      };
      const params = {
        ...queryParams,
        meta: JSON.stringify(meta),
      };
      //return uploadData(data, image.type, token, params);
      const queryString =
        "?" +
        Object.keys(params)
          .map((key: string) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(
              params[key]
            )}`;
          })
          .join("&");

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${token}`);
      headers.append("Content-Type", file.type);

      const response = await fetch(`${baseUrl}/${queryString}`, {
        method: "PUT",
        body: file,
        headers,
      });

      if (response.ok) {
        const json = await response.json();
        let requestId;
        requestId;
        response.headers.forEach((value, key) => {
          if (key === "x-amz-cf-id") {
            requestId = value;
          }
        });
        if (json.id) {
          setFileId(json.id);
          return { id: json?.id };
        }
      }
    }
    return Promise.reject("unable to upload image");
  };

  return { uploadImage, uploadedImage: fileData?.fileById };
};

export default useFileApi;
