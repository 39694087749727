import React, { useEffect, useState } from "react";
import {
  BikeFieldsFragment,
  BikeByIdQuery,
} from "graphql/generated/resourceApi";
import DetailTable from "components/Detail/DetailTable";
import { Image } from "primereact/image";
import { UseQueryResult } from "@tanstack/react-query";

interface BikeDetailProps {
  bike?: BikeFieldsFragment;
  showEdit?: boolean;
  bikeState?: UseQueryResult<BikeByIdQuery, unknown>;
}

const BikeDetail: React.FC<BikeDetailProps> = ({ bike, showEdit }) => {
  const [showEditBikeDialog, setShowEditBikeDialog] = useState(false);
  const [currentBike, setCurrentBike] = useState<
    BikeFieldsFragment | undefined
  >(bike);
  showEditBikeDialog;

  const title = currentBike?.name;
  useEffect(() => {
    setShowEditBikeDialog(showEdit || false);
    if (bike) {
      setCurrentBike(bike);
    }
  }, [showEdit, bike]);

  return (
    <div>
      <div className="flex flex-col-reverse lg:flex-row justify-center gap-6">
        <div className="w-full lg:w-4/5">
          {currentBike && (
            <>
              <DetailTable
                title={title}
                data={currentBike}
                fields={{
                  name: { label: "Name", value: "name" },
                  make: { label: "Make", value: "bikeModel.bikeMake.name" },
                  model: { label: "Model", value: "bikeModel.name" },
                  year: { label: "Year", value: "year" },
                }}
                onEditButtonClick={() => setShowEditBikeDialog(true)}
              />
              <div className="mb-4" />
            </>
          )}
        </div>
        <div className="w-full lg:w-1/5" style={{ margin: "16px auto" }}>
          <Image
            className="flex justify-center"
            imageStyle={{ maxHeight: "288px" }}
            src={currentBike?.file?.url ?? undefined}
            alt="Bike Image"
            preview={!!currentBike?.file?.url}
          />
        </div>
      </div>
    </div>
  );
};

export default BikeDetail;
