import { keys } from 'lodash'

export const getCapitalizedText = (text: string): string => {
  const words = text.split(' ')
  return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ')
}

export const nullStringify = (text: string | null | undefined): string => {
  if (text === null || text === undefined) return ''
  return text
}

export const getThemePropertyValue = (property: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(property)
}

export const getExportObjectString = (objectArray: { [x: string]: any }[], excludedKeys: string[], nestedObjectKeys?: string[]): string => {
  let objectString = ''
  objectArray.forEach(object => {
    objectString += '{'
    for (const objKey in object) {
      if (excludedKeys.includes(objKey)) continue
      if (nestedObjectKeys && nestedObjectKeys.includes(objKey)) {
        objectString += `${objKey} : {data : {`
        keys(object[objKey]).forEach((nestedKey: string) => {
          if (excludedKeys.includes(nestedKey)) return
          objectString += `${nestedKey} : ${object[objKey][nestedKey]}, `
        })
        objectString = `${objectString.slice(0, -2)}}}, `
        continue
      }
      objectString += `${objKey} : ${object[objKey]}, `
    }
    objectString = `${objectString.slice(0, -2)}}, `
  })
  return `[${objectString.slice(0, -2)}]`
}
