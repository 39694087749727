import { DocumentNode, GraphQLSchema, Kind, print } from "graphql";
import { GraphQLClient } from "graphql-request";
import { Variables } from "graphql-request/build/esm/types";
import { DataTableSortEvent } from "primereact/datatable";

export interface InfiniteQueryOptions extends Variables {
  limit?: number;
  offset?: number;
  where?: Record<string, any>;
  orderBy?: Record<string, any>;
  distinctOn?: string[];
  abortPreviousQuery?: boolean;
}

export interface InfiniteQueryResponse<T> {
  current: T[];
  aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export type HasuraGraphQLNamingConvention = "hasuraDefault" | "graphqlDefault";

export class HasuraDataAdapter {
  client: GraphQLClient;

  typename: string;

  fieldsFragment: DocumentNode;

  namingConvention: HasuraGraphQLNamingConvention;

  schema?: GraphQLSchema;

  constructor(
    client: GraphQLClient,
    typename: string,
    fieldsFragment: DocumentNode,
    namingConvention: HasuraGraphQLNamingConvention,
    schema?: GraphQLSchema
  ) {
    this.client = client;
    this.typename = typename;
    this.fieldsFragment = fieldsFragment;
    this.namingConvention = namingConvention;
    this.schema = schema;
  }

  async infiniteManyQuery<T>(
    options?: InfiniteQueryOptions,
    fieldsFragmentOverride?: DocumentNode,
    abortSignal?: AbortSignal
  ): Promise<InfiniteQueryResponse<T>> {
    const document = this.buildInfiniteManyQuery(fieldsFragmentOverride);
    const results = await this.client.request<InfiniteQueryResponse<T>, InfiniteQueryOptions>({
      document,
      variables: options ?? {},
      signal: abortSignal,
    });
    return results;
  }

  private buildInfiniteManyQuery(
    fieldsFragmentOverride?: DocumentNode
  ): string {
    const fragmentDoc = fieldsFragmentOverride || this.fieldsFragment;
    const fragmentDefinition = fragmentDoc.definitions.find(
      (node) => node.kind === Kind.FRAGMENT_DEFINITION
    );
    if (
      !fragmentDefinition ||
      fragmentDefinition.kind !== Kind.FRAGMENT_DEFINITION
    ) {
      throw new Error(
        `document node does not have a fragment ${print(fragmentDoc)}`
      );
    }
    const fieldsFragmentName = fragmentDefinition.name.value;
    const capitalizeTypename =
      this.typename.charAt(0).toUpperCase() + this.typename.slice(1);

    if (this.namingConvention === "hasuraDefault") {
      return `
      query ${this.typename}List(
        $where: ${capitalizeTypename}BoolExp
        $orderBy: [${capitalizeTypename}OrderBy!]
        $offset: Int
        $limit: Int
        $distinctOn: [${capitalizeTypename}SelectColumn!]
      ) {
        current:${this.typename}(
          where: $where
          orderBy: $orderBy
          offset: $offset
          limit: $limit
          distinctOn: $distinctOn
        ) {
          ...${fieldsFragmentName}
        }
        aggregate:${this.typename}Aggregate(
          where: $where
          distinctOn: $distinctOn
        ) {
          aggregate {
            count
          }
        }
      }
      ${print(fragmentDoc)}`;
    }

    const typeNamePascal =
      this.typename[0].toUpperCase() + this.typename.slice(1);

    return `
    query ${this.typename}List(
      $where: ${typeNamePascal}BoolExp
      $orderBy: [${typeNamePascal}OrderBy!]
      $offset: Int
      $limit: Int
      $distinctOn: [${typeNamePascal}SelectColumn!]
    ) {
      current:${this.typename}(
        where: $where
        orderBy: $orderBy
        offset: $offset
        limit: $limit
        distinctOn: $distinctOn
      ) {
        ...${fieldsFragmentName}
      }
      aggregate:${this.typename}Aggregate(
        where: $where
        distinctOn: $distinctOn
      ) {
        aggregate {
          count
        }
      }
    }
    ${print(fragmentDoc)}`;
  }
}

export function buildObjectForPath(path: string[], leafValue: any): Record<string, any> {
  const [part, ...rest] = path;
  if (rest.length) {
    return {
      [part]: buildObjectForPath(rest, leafValue)
    };
  }
  return {
    [part]: leafValue
  };
}

export function buildOrderBy(
  namingConvention: HasuraGraphQLNamingConvention,
  sortField?: string,
  sortOrder?: DataTableSortEvent["sortOrder"],
  _multiSortMeta?: DataTableSortEvent["multiSortMeta"]
): Record<string, string> | undefined {
  // TODO: handle multiSort
  let sortMap = {
    "1": "ASC_NULLS_LAST",
    "-1": "DESC_NULLS_LAST",
  };
  if (namingConvention === "hasuraDefault") {
    sortMap = {
      '1': 'asc_nulls_last',
      '-1': 'desc_nulls_last'
    }
  }
  if (!sortOrder || !sortField) {
    return undefined;
  }
  const hasuraSortDirection = sortMap[`${sortOrder}`];
  if (hasuraSortDirection) {
    const path = sortField.split(".");
    return buildObjectForPath(path, hasuraSortDirection);
  }
  return undefined;
}
