import React from "react";
import FileImage from "components/FileImage";
import { Image } from "primereact/image";
import CompanyLogoDefault from "assets/images/company-logo-default.png";
import get from "lodash/get";
import { Button } from "primereact/button";

interface Field {
  label: string;
  value: string;
  default?: string;
  override?: (value: any) => string;
}

interface DetailTableProps {
  data: { [key: string]: any };
  fields: { [key: string]: Field };
  title?: string;
  image?: string;
  components?: { [key: string]: (value: any) => JSX.Element };
  showImage?: boolean;
  onEditButtonClick: () => void;
}

const DetailTable: React.FC<DetailTableProps> = ({
  data,
  fields,
  title,
  image,
  showImage,
  components,
  onEditButtonClick,
}) => {
  const defaultValue = "-";
  if (data && data.date_of_birth) {
    data.date_of_birth = new Date(data.date_of_birth).toLocaleDateString(
      "en-US"
    );
  }

  return (
    <>
      {title && (
        <div
          className="py-1 mb-2 font-semibold text-xl border-solid border-b-2
        p-primary-border-color w-full flex flex-row items-center justify-between"
        >
          <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
            <i
              className="pi pi-user mr-3 p-text-secondary"
              style={{ fontSize: "1.4em" }}
            />
            {title}
          </div>
          <Button
            label="Edit Details"
            icon="pi pi-pencil"
            className="p-button-text text-primary"
            onClick={onEditButtonClick}
          />
        </div>
      )}
      <div className="flex flex-wrap justify-between items-center gap-4">
        {image && get(data, image) && (
          <FileImage
            file={get(data, image)}
            className="photo-wrapper overflow-hidden rounded-full"
            width="280px"
            height="280px"
          />
        )}
        <table className="text-m">
          <tbody>
            {Object.keys(fields).map((fieldName) => {
              let fieldValue = get(data, fields[fieldName].value);
              fieldValue = fields[fieldName].override?.(fieldValue) ?? fieldValue;
    
              return (
                <tr key={fieldName} className="m-1">
                  <td className="font-semibold p-text-secondary">
                    {fields[fieldName].label}
                  </td>
                  <td
                    className={`pl-3 max-w-xl ${!fieldValue ? "italic" : null}`}
                  >
                    {components?.[fieldName]
                      ? components[fieldName](fieldValue)
                      : fieldValue || fields[fieldName].default || defaultValue}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="col-12 md:col-4 pl-10 pr-20 flex-order-1 md:flex-order-2 w-1/4">
          {showImage && (
            <>
              <Image
                src={image || CompanyLogoDefault}
                alt="Default Company Logo"
                preview
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailTable;
