import React, { useState } from "react";
//import HasuraConfig from "../../../shared/graphql/hasuraConfig";
//import { useReactGraphql } from "@tesseractcollective/react-graphql";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
//import { ScalarComponentPropsBase } from "../../../shared/ScalarComponentPropsBase";
import "leaflet/dist/leaflet.css";
//import { useController } from "react-hook-form";
import { collectiveIcon } from "utils/leaflet/collectiveIcon";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useDeleteGroupLocationMutation } from "graphql/generated/resourceApi";
import { useClient } from "shared/UserContext";
import { LocationForm } from "./LocationForm";

export const LocationField: React.FC<{
  location?: any;
  toastRef?: any;
  setLocation: (location: any) => void;
}> = ({ location, toastRef, setLocation }) => {
  const client = useClient();
  const [deleteLocationDialog, setDeleteLocationDialog] = useState(false);
  const [locationDialogVisible, setLocationDialogVisible] = useState(false);

  /*const {
    field: { ref, ...inputProps },
  } = useController({
    name: props.fieldInfo.name,
    control: props.control,
    defaultValue: null,
  });*/

  const deleteLocation = useDeleteGroupLocationMutation(client, {
    onSuccess: () => {
      setLocation && setLocation(undefined);
    },
  });

  const onShowDeleteLocationDialog = () => {
    setDeleteLocationDialog(true);
  };

  const onHideDeleteLocationDialog = () => {
    setDeleteLocationDialog(false);
  };

  const onDeleteLocation = () => {
    deleteLocation.mutate({ id: location.id });
  };

  const onShowLocationDialog = () => {
    setLocationDialogVisible(true);
  };

  const onHideLocationDialog = () => {
    setLocationDialogVisible(false);
  };

  return (
    <>
      {location ? (
        <>
          <div className="flex flex-row">
            <div className="p-inputgroup">
              <div className="p-float-label">
                <InputText
                  id="ff-location"
                  className="p-inputtext-sm bg-purco-blue-primary "
                  name="Location"
                  value={
                    location &&
                    `${location?.name} - (${location?.latitude},${location?.longitude})`
                  }
                  disabled
                />
                <label htmlFor="ff-location">Location</label>
              </div>
            </div>
            {location && (
              <Button
                icon="pi pi-trash"
                label="Delete"
                onClick={onShowDeleteLocationDialog}
                type="button"
                className="ml-2 w-40 p-button-danger"
              />
            )}
            <Button
              icon="pi pi-pencil"
              label="Edit"
              onClick={onShowLocationDialog}
              type="button"
              className="ml-2 w-40"
            />
          </div>
          <ConfirmDialog
            visible={deleteLocationDialog}
            onHide={onHideDeleteLocationDialog}
            message="Are you sure you want to delete this Location?"
            header="Delete Location"
            icon="pi pi-exclamation-triangle"
            accept={onDeleteLocation}
          />
          <MapContainer
            center={[location?.latitude || 40, location?.longitude || -100]}
            zoom={5}
            scrollWheelZoom={false}
            style={{ width: "100%", height: 400 }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={[location?.latitude, location?.longitude]}
              key={`location-marker-group`}
              icon={collectiveIcon}
            >
              <Popup>{location.name}</Popup>
            </Marker>
          </MapContainer>
        </>
      ) : (
        <div className="p-inputgroup">
          <div className="p-float-label">
            <InputText
              id="ff-location"
              className="p-inputtext-sm bg-purco-blue-primary "
              name="Location"
              value={
                location
                  ? `${location?.name} - (${location?.latitude},${location?.longitude})`
                  : ""
              }
              disabled
            />
            <label htmlFor="ff-location">Location</label>
          </div>
          {location && (
            <Button
              icon="pi pi-trash"
              label="Delete"
              onClick={onShowDeleteLocationDialog}
              type="button"
              className="pr-4 p-button-danger"
            />
          )}
          <Button
            icon="pi pi-pencil"
            label="Edit"
            onClick={onShowLocationDialog}
            type="button"
            className="pr-4"
          />
          <ConfirmDialog
            visible={deleteLocationDialog}
            onHide={onHideDeleteLocationDialog}
            message="Are you sure you want to delete this Location?"
            header="Delete Location"
            icon="pi pi-exclamation-triangle"
            accept={onDeleteLocation}
          />
        </div>
      )}
      <LocationForm
        toastRef={toastRef}
        location={location}
        onHide={onHideLocationDialog}
        visible={locationDialogVisible}
        setLocation={setLocation}
      />
    </>
  );
};
