// eslint-disable-next-line max-classes-per-file
import { DataTableFilterMeta, DataTableSortEvent } from "primereact/datatable";

import {
  buildWhere,
  flattenAttributesForPath,
  valueForPath,
  valueFromColumnBody,
} from "./adminTableUtils";
import { HasuraDataAdapter, buildOrderBy } from "../DataAdapter";
import log from "log";
import { AdminTableState } from "./AdminTable";
import { ColumnProps } from "primereact/column";

export type WhereClause = Record<string, any>;

export interface AdminTableAdapterState {
  current: any[];
  total: number;
  first: number;
  rows: number;
  sortField?: string;
  sortOrder?: DataTableSortEvent["sortOrder"];
  filters?: DataTableFilterMeta;
  error?: string;
}

export type AdminTableAdapterEvent = "reload";

export class AdminTableHasuraAdapter {
  dataAdapter: HasuraDataAdapter;
  baseWhere?: WhereClause;
  private abortController?: AbortController;

  events: Record<AdminTableAdapterEvent, EventListener[]> = {
    reload: [],
  };

  constructor(dataAdapter: HasuraDataAdapter, baseWhere?: WhereClause) {
    this.dataAdapter = dataAdapter;
    this.baseWhere = baseWhere;
  }

  async handleAdminTableState(
    e: AdminTableState,
    fields: string[]
  ): Promise<AdminTableAdapterState> {
    const rows = e.rowsPerPage;
    const first = e.page * rows;
    const where = buildWhere(
      e?.filters,
      e?.globalFilter,
      e?.globalFilterFields,
      this.baseWhere
    );
    const orderBy = buildOrderBy(
      this.dataAdapter.namingConvention,
      e?.sortField,
      e?.sortOrder
    );

    let current: any[] = [];
    let total = 0;
    let error: string | undefined;

    // stop previous call
    if (this.abortController) {
      this.abortController.abort();
    }

    try {
      this.abortController = new AbortController();
      const result = await this.dataAdapter.infiniteManyQuery(
        {
          limit: rows,
          offset: first,
          where,
          orderBy,
        },
        undefined,
        this.abortController.signal
      );
      this.abortController = undefined;

      current =
        result.current.map((item: any) => {
          const row: any = {};
          fields.forEach((field) => {
            const path = field.split(".");
            row[path[0]] = flattenAttributesForPath(path, item, row[path[0]]);
          });
          return {
            ...item,
            ...row,
          };
        }) || [];
      total = result.aggregate.aggregate.count || 0;
    } catch (graphqlError) {
      // TODO: parse error
      log.error("AdminTableAdapter error", graphqlError);
      error = graphqlError;
      this.abortController = undefined;
    }

    const state: AdminTableAdapterState = {
      current,
      total,
      error,
      first,
      rows,
      filters: e?.filters,
      sortField: e?.sortField,
      sortOrder: e?.sortOrder,
    };
    return state;
  }

  async fetchAllAsRecords(
    e: AdminTableState,
    fields: string[],
    columnProps: ColumnProps[],
    progressCallback?: (current: number, total: number) => void
  ): Promise<Record<string, any>[]> {
    const limit = 200;
    let offset = 0;
    const where = buildWhere(
      e?.filters,
      e?.globalFilter,
      e?.globalFilterFields,
      this.baseWhere
    );
    const orderBy = buildOrderBy(
      this.dataAdapter.namingConvention,
      e?.sortField,
      e?.sortOrder
    );

    const records: Record<string, any>[] = [];
    let total = Number.MAX_SAFE_INTEGER;

    while (records.length < total) {
      try {
        const result = await this.dataAdapter.infiniteManyQuery(
          {
            limit,
            offset,
            where,
            orderBy,
          },
          undefined
        );

        const current =
          result.current.map((item: any) => {
            const flattenedItem: any = {};
            fields.forEach((field) => {
              const path = field.split(".");
              flattenedItem[path[0]] = flattenAttributesForPath(
                path,
                item,
                flattenedItem[path[0]]
              );
            });
            const row: any = {};
            fields.forEach((field) => {
              const path = field.split(".");
              row[field] =
                valueForPath(path, flattenedItem) ||
                valueFromColumnBody(field, columnProps, item);
              if (typeof row[field] === "object") {
                row[field] = JSON.stringify(row[field]);
              }
            });
            console.log(row);
            return row;
          }) || [];

        total = result.aggregate.aggregate.count ?? 0;
        records.push(...current);
        progressCallback?.(records.length, total);
        offset += limit;
      } catch (graphqlError) {
        // TODO: parse error
        log.error("AdminTableAdapter error", graphqlError);
        this.abortController = undefined;
      }
    }

    return records;
  }

  on(event: AdminTableAdapterEvent, listener: EventListener): void {
    this.events[event].push(listener);
  }

  removeListener(event: AdminTableAdapterEvent, listener: EventListener): void {
    const index = this.events[event].findIndex((value) => value === listener);
    if (index > -1) {
      this.events[event].splice(index, 1);
    }
  }

  emit(event: AdminTableAdapterEvent): void {
    for (const listener of this.events[event]) {
      listener(new Event(event));
    }
  }

  reload(): void {
    this.emit("reload");
  }
}
