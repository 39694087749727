import axios from "axios";

export async function reverseGeocoder(latitude: number, longitude: number) {
  const token =
    "AAPK106943cf591d44eca2c185fa471f5b65QHXL_UMxtoM5-gukHPepT_xhYVUsUwN3ETAPtYT4Hrgr4R0uhLqiaPUG55P8vOS9";
  try {
    const response = await axios.get(
      `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?outSR=4326&token=${token}&returnIntersection=false&location=${longitude}%2C${latitude}&f=json`
    );
    return response.data.address;
  } catch (err) {
    console.error(err);
  }
}
