import React, { useState } from "react";
import { UserFieldsFragment } from "graphql/generated/resourceApi";
import { Image } from "primereact/image";
import { useUserContext } from "shared/UserContext";
import { Dialog } from "primereact/dialog";
import UserEdit from "./UserEdit";
import { Button } from "primereact/button";
import { ConfirmDialog } from "primereact/confirmdialog";
import { userLocationName, valueForUserAttribute } from "utils/userUtils";
import DetailRow from "components/Detail/DetailRow";
import { ProgressSpinner } from "primereact/progressspinner";
interface UserDetailProps {
  user: UserFieldsFragment;
  onUpdate: ({
    title,
    message,
    user,
  }: {
    title: string;
    message: string;
    user?: UserFieldsFragment;
  }) => void;
}

const UserDetail: React.FC<UserDetailProps> = (props) => {
  const { user, onUpdate } = props;
  const { sdkClient, token } = useUserContext();
  const [editUserVisible, setEditUserVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  const url = user.profileImage?.url
    ? `${user.profileImage.url}&token=${token}`
    : undefined;

  const deleteUser = async () => {
    setDeleteLoading(true);
    const result = await sdkClient.deleteUser({ id: user.id });
    if (result.deleteUserByPk?.id) {
      onUpdate({
        title: "Delete Successful",
        message: `${user.primaryEmail} was successfully deleted`,
      });
    }
    setDeleteLoading(false);
  };

  const role = valueForUserAttribute("hasuraRole", user);

  return (
    <div>
      <Dialog
        visible={editUserVisible}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setEditUserVisible(false)}
        header="Edit User"
      >
        <UserEdit
          user={user}
          onUpdate={(data) => {
            setEditUserVisible(false);
            onUpdate(data);
          }}
        />
      </Dialog>
      <Dialog
        visible={deleteLoading}
        modal
        style={{ width: "300px" }}
        onHide={() => {}}
        header="Deleting User"
        closable={false}
      >
        <ProgressSpinner className="flex items-center justify-center" />
      </Dialog>
      <ConfirmDialog
        visible={deleteConfirmationVisible}
        onHide={() => setDeleteConfirmationVisible(false)}
        message={
          <>
            <p>Are you sure you want to delete user {user.primaryEmail}?</p>
            <p>(this can take several seconds to complete)</p>
          </>
        }
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        acceptLabel="Delete"
        rejectLabel="Cancel"
        acceptClassName="p-button-danger"
        accept={deleteUser}
      />
      <div className="flex flex-col-reverse lg:flex-row justify-center gap-6">
        <div className="w-full lg:w-4/5">
          <div
            className="py-1 mb-2 font-semibold text-xl border-solid border-b-2
        p-primary-border-color w-full flex flex-row items-center justify-between"
          >
            <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
              <i
                className="pi pi-user mr-3 p-text-secondary"
                style={{ fontSize: "1.4em" }}
              />
              {user.name}
            </div>
            <div>
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text text-primary"
                onClick={() => setEditUserVisible(true)}
              />
              <Button
                label="Delete"
                icon="pi pi-trash"
                className="p-button-text p-button-danger"
                onClick={() => setDeleteConfirmationVisible(true)}
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-between items-center gap-4">
            <table className="text-m">
              <tbody>
                <DetailRow name="Email" value={user.primaryEmail} />
                <DetailRow
                  name="Email Verified"
                  value={user.primaryEmailIsVerified ? "Yes" : "No"}
                />
                <DetailRow name="Role" value={role} />
                <DetailRow name="First Name" value={user.firstName} />
                <DetailRow name="Last Name" value={user.lastName} />
                <DetailRow name="Location" value={userLocationName(user)} />
                <DetailRow
                  name="Phone Number"
                  value={valueForUserAttribute("phoneNumber", user)}
                />
                <DetailRow
                  name="Pronouns"
                  value={valueForUserAttribute("pronouns", user)}
                />
                <DetailRow
                  name="Birthdate"
                  value={valueForUserAttribute("dateOfBirth", user, "dateOfBirth")}
                />
                <DetailRow
                  name="Website"
                  value={valueForUserAttribute("website", user)}
                />
                <DetailRow
                  name="Instagram"
                  value={valueForUserAttribute("instagramLink", user)}
                />
                <DetailRow
                  name="Riding Since"
                  value={valueForUserAttribute("ridingSince", user, "ridingSince")}
                />
                <DetailRow
                  name="Bio"
                  value={valueForUserAttribute("bio", user)}
                />
                <DetailRow
                  name="Registered On"
                  value={new Date(user.createdAt).toLocaleDateString()}
                />
                <DetailRow name="ID" value={user.id} />
                
              </tbody>
            </table>
          </div>
        </div>
        <div
          className="w-full lg:w-1/5"
          style={{ margin: "16px auto", width: "288px" }}
        >
          <Image
            className="flex justify-center"
            imageStyle={{ objectFit: "cover" }}
            src={url ?? undefined}
            alt="User Avatar"
            preview={!!url}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
