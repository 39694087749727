import { Icon } from "leaflet";
import MapMarker from "./mapMarker.svg";

const collectiveIcon = new Icon({
  iconUrl: MapMarker,
  iconRetinaUrl: MapMarker,
  iconSize: [24, 32],
  iconAnchor: [12, 31],
  popupAnchor: [0, -16],
  className: "leaflet-marker-icon leaflet-zoom-animated leaflet-interactive",
});

export { collectiveIcon };
