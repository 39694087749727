/* eslint-disable no-await-in-loop */
import React, { useState, useRef, useEffect } from "react";
import {
  AdminTable,
  AdminTableState,
  dateBody,
  dateFilterTemplate,
  getInitial,
  imageBody,
} from "components/Admin";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";

import { BikeAdminTableFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useAuthToken } from "shared/UserContext";
import { useDataAdapter } from "hooks/useDataAdapter";
import UserTabs, {
  dialogWithTabsTableHeightOffset,
} from "components/User/UserTabs";
import { roleFilterElement, yearsRidingBody } from "components/User/UsersTable";
export interface BikesTableProps {
  userId?: string;
  disableRowButton?: boolean;
  tableStateOverride?: Partial<AdminTableState>;
  onTableStateEvent?: (e: AdminTableState) => void;
  hideHeader?: boolean;
  tableHeight?: number;
}

const BikesTable: React.FC<BikesTableProps> = ({
  userId,
  disableRowButton,
  tableStateOverride,
  onTableStateEvent,
  hideHeader,
  tableHeight,
}) => {
  const dialogRef = useRef<Dialog>(null);
  const [dialogHeight, setDialogHeight] = useState(0);
  const toast = useRef<Toast>(null);
  const token = useAuthToken();
  const { tableAdapter } = useDataAdapter(
    "bike",
    BikeAdminTableFieldsFragmentDoc,
    userId
      ? {
          userId: { _eq: userId },
        }
      : undefined
  );

  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [bikeUserId, setBikeUserId] = useState<string>();

  const initialFilters = {
    "bikeModel.bikeMake.name": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "bikeModel.name": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    name: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    year: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "user.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.firstName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.lastName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: [
      "name",
      "bikeModel.name",
      "bikeModel.bikeMake.name",
      "user.name",
    ],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
    ...tableStateOverride,
  });
  const handleOnStateEvent = (state: AdminTableState) => {
    setTableState(state);
    onTableStateEvent?.(state);
  };
  useEffect(() => {
    setTableState((previousTableState) => ({
      ...previousTableState,
      ...tableStateOverride,
    }));
  }, [tableStateOverride]);

  const getInitials = (data: any) => {
    return getInitial(data.name);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={showUserModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowUserModal(false)}
        onResize={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
        onShow={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
      >
        <UserTabs
          userId={bikeUserId}
          activeTab="garage"
          tableHeight={dialogHeight - dialogWithTabsTableHeightOffset}
          onUpdate={() => {}}
        />
      </Dialog>

      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <h1 className="text-3xl font-bold m-4">Garage</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={handleOnStateEvent}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search bike name, make, model or user name"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        onRowSelect={(e: DataTableSelectEvent) => {
          if (!disableRowButton) {
            setBikeUserId(e.data.userId);
            setShowUserModal(true);
          }
        }}
        tableHeight={tableHeight}
        defaultFieldsSelected={[
          "file.url",
          "user.primaryEmail",
          "user.firstName",
          "user.lastName",
          "bikeModel.bikeMake.name",
          "bikeModel.name",
          "year",
          "name",
          "createdAt",
        ]}
      >
        <Column
          field="file.url"
          header="Image"
          body={imageBody(token, getInitials)}
        />
        <Column
          field="user.primaryEmail"
          header="User Email"
          sortable
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="user.firstName"
          header="User First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.lastName"
          header="User Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />

        <Column
          field="bikeModel.bikeMake.name"
          header="Bike Make"
          sortable
          filter
          style={{
            minWidth: "250px",
          }}
        />
        <Column
          field="bikeModel.name"
          header="Bike Model"
          sortable
          filter
          style={{
            minWidth: "250px",
          }}
        />
        <Column
          field="year"
          header="Bike Year"
          sortable
          filter
          dataType="numeric"
          filterType="number"
        />
        <Column
          field="name"
          header="Bike Nickname"
          sortable
          body={(row: any) => {
            return row.name ?? "-";
          }}
          style={{
            minWidth: "150px",
          }}
        />
        <Column
          field="user.attributes.hasuraRole"
          header="Role"
          filterElement={roleFilterElement}
          // filter
        />
        <Column
          field="user.postsCount"
          header="Posts"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.groupsCount"
          header="Groups"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.blocksCount"
          header="Blocked By"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.bikesCount"
          header="Bikes"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.homeLocation.name"
          header="Location"
          style={{
            minWidth: "200px",
          }}
          sortable
          // filter
        />
        <Column
          field="user.attributes.pronouns"
          header="Pronouns"
          // filter
        />
        <Column
          field="user.attributes.phoneNumber"
          header="Phone Number"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.dateOfBirth"
          header="Birth Date"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
        />
        <Column
          field="user.attributes.ridingSince"
          header="Years Riding"
          body={yearsRidingBody}
        />
        <Column
          field="user.attributes.website"
          header="Website"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.lastActivity"
          header="Last Activity"
          body={dateBody}
          dataType="date"
        />
        <Column
          field="user.attributes.instagramLink"
          header="IG Handle"
          style={{
            minWidth: "150px",
          }}
          filter
        />
        <Column
          field="createdAt"
          header="Created On"
          body={dateBody}
          dataType="date"
          filterType="date"
          filterElement={dateFilterTemplate}
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default BikesTable;
