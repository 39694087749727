import React, { useCallback, useEffect } from 'react'
import ReactSwitch from 'react-switch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb, faMoon } from '@fortawesome/free-solid-svg-icons'
import useLayoutDimensions from 'hooks/useLayoutDimensions'
import useLocalStorage from '../../utils/useLocalStorage'
import './ThemeSwitch.scss'
import { darkTheme, lightTheme } from '../../../theme-colors'

const userPrefersDarkMode = window.matchMedia('(prefers-color-scheme:dark)').matches
// prevents 'Element type is invalid' error in build
const Switch = (ReactSwitch as any).default ? (ReactSwitch as any).default : ReactSwitch

export const ThemeSwitch: React.FC = () => {
  const { isDesktop } = useLayoutDimensions()
  const isMobile = !isDesktop;
  const [isDarkFromLocalStorage, setIsDarkInLocalStorage] = useLocalStorage<boolean>('isDark', userPrefersDarkMode)

  const onThemeChange = useCallback((isDarkTheme: boolean): void => {
    if (isDarkTheme) {
      Object.keys(darkTheme).forEach(key => {
        document.documentElement.style.setProperty(key, darkTheme[key])
      })
    } else {
      Object.keys(lightTheme).forEach(key => {
        document.documentElement.style.setProperty(key, lightTheme[key])
      })
    }
  }, [])

  const onChangeTheme = useCallback(
    (val: boolean) => {
      onThemeChange(val)
      setIsDarkInLocalStorage(val)
    },
    [onThemeChange, setIsDarkInLocalStorage]
  )

  useEffect(() => {
    onChangeTheme(isDarkFromLocalStorage)
  }, [isDarkFromLocalStorage, onChangeTheme])

  return (
    <Switch
      checked={isDarkFromLocalStorage}
      onChange={onChangeTheme}
      handleDiameter={isMobile ? 19 : 16}
      height={isMobile ? 0 : 20}
      width={isMobile ? 2 : 40}
      uncheckedIcon={isMobile ? undefined : <FontAwesomeIcon icon={faMoon} />}
      uncheckedHandleIcon={isMobile ? <FontAwesomeIcon icon={faMoon} /> : undefined}
      checkedIcon={isMobile ? undefined : <FontAwesomeIcon icon={faLightbulb} />}
      checkedHandleIcon={isMobile ? <FontAwesomeIcon icon={faLightbulb} /> : undefined}
      onHandleColor={isMobile ? '#00cfe5' : undefined}
      offHandleColor={isMobile ? '#1565C0' : undefined}
      className={`react-switch ${isDarkFromLocalStorage ? 'dark' : 'light'}`}
    />
  )
}
