/* eslint-disable no-await-in-loop */
import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  AdminTable,
  AdminTableState,
  WhereClause,
  booleanFilterTemplate,
  dateBody,
  dateFilterTemplate,
} from "components/Admin";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import { type GroupFieldsFragment } from "graphql/generated/resourceApi";
import { GroupFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useDataAdapter } from "hooks/useDataAdapter";
import GroupEdit from "./GroupEdit";
import { Checkbox } from "primereact/checkbox";
import GroupTabs from "./GroupTabs";
export interface GroupsTableProps {
  userId?: string;
  tableStateOverride?: Partial<AdminTableState>;
  onTableStateEvent?: (e: AdminTableState) => void;
  hideHeader?: boolean;
  tableHeight?: number;
}

const GroupsTable: React.FC<GroupsTableProps> = ({
  userId,
  tableStateOverride,
  onTableStateEvent,
  hideHeader,
  tableHeight,
}) => {
  const toast = useRef<Toast>(null);
  const [onlyInterestCollectives, setOnlyInterestCollectives] =
    useState<boolean>(false);

  const baseWhere: WhereClause = userId
    ? {
        userGroups: { userId: { _eq: userId } },
      }
    : {};
  const { tableAdapter } = useDataAdapter(
    "group",
    GroupFieldsFragmentDoc,
    baseWhere
  );

  const [showEditGroupDialog, setShowEditGroupDialog] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false);
  const [currentGroup, setCurrentGroup] = useState<
    GroupFieldsFragment | undefined
  >();

  const initialFilters = {
    name: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    description: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    isHidden: { value: null, matchMode: FilterMatchMode.EQUALS },
    location: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    postsCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    usersCount: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    postsCountLastSevenDays: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    postsCountLastThirtyDays: {
      operator: FilterOperator.OR,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
      ],
    },
    lastPostCreatedAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
    isInterestCollective: { value: null, matchMode: FilterMatchMode.EQUALS },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["name", "description"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
    ...tableStateOverride,
  });
  const handleOnStateEvent = (state: AdminTableState) => {
    setTableState(state);
    onTableStateEvent?.(state);
  };
  useEffect(() => {
    setTableState((previousTableState) => ({
      ...previousTableState,
      ...tableStateOverride,
    }));
  }, [tableStateOverride]);

  useEffect(() => {
    const value = onlyInterestCollectives ? true : null;
    setTableState((previous) => {
      return {
        ...previous,
        filters: {
          ...previous.filters,
          isInterestCollective: { value, matchMode: FilterMatchMode.EQUALS },
        },
      };
    });
  }, [onlyInterestCollectives]);

  const onClear = () => {
    setOnlyInterestCollectives(false);
  };

  const isHidden = (row: any): React.ReactNode => {
    return row.isHidden ? "Yes" : "No";
  };

  const onComplete = useCallback((): void => {
    setShowEditGroupDialog(false);
    setCurrentGroup(undefined);
    tableAdapter.reload();
  }, [tableAdapter]);

  const onUpdate = useCallback((): void => {
    tableAdapter.reload();
    toast.current?.show({
      severity: "success",
      summary: "Collective Updated",
      detail: "Collective was successfully updated!",
    });
    setShowGroupModal((prev) => !prev);
  }, [tableAdapter]);

  return (
    <div>
      <Toast ref={toast} />
      <GroupEdit
        onComplete={onComplete}
        showEditGroupDialog={showEditGroupDialog}
        setShowEditGroupDialog={setShowEditGroupDialog}
      />

      <Dialog
        visible={showGroupModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowGroupModal(false)}
      >
        <GroupTabs group={currentGroup!} onUpdate={onUpdate} />
      </Dialog>

      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-users text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Collectives</h1>
          </div>
          <div>
            <Button
              className="my-4 p-background-primary-color"
              type="button"
              label="Create Collective"
              icon="pi pi-plus"
              onClick={() => setShowEditGroupDialog(true)}
            />
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={handleOnStateEvent}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search collective name or description"
        onClear={onClear}
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        onRowSelect={(e: DataTableSelectEvent) => {
          setCurrentGroup(e.data);
          setShowGroupModal(true);
        }}
        tableHeight={tableHeight}
        header={
          !hideHeader && (
            <div className="flex flex-row items-center mt-2 p-text-primary">
              <Checkbox
                onChange={(event) => {
                  setOnlyInterestCollectives(event.checked ?? false);
                }}
                checked={onlyInterestCollectives}
              />
              <div className="ml-2">Interest Collectives Only</div>
            </div>
          )
        }
      >
        <Column
          field="name"
          header="Name"
          sortable
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="description"
          header="Description"
          filter
          sortable
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="isHidden"
          header="Hidden"
          sortable
          filter
          dataType="boolean"
          filterElement={booleanFilterTemplate}
          body={isHidden}
          style={{
            minWidth: "150px",
          }}
        />
        <Column
          field="groupLocation"
          header="Location"
          sortable
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="postsCount"
          header="Posts"
          sortable
          filter
          dataType="numeric"
          filterType="number"
        />
        <Column
          field="usersCount"
          header="Users"
          sortable
          filter
          dataType="numeric"
          filterType="number"
        />
        <Column
          field="postsCountLastSevenDays"
          header="Posts in Last 7 Days"
          sortable
          filter
          dataType="numeric"
          filterType="number"
        />
        <Column
          field="postsCountLastThirtyDays"
          header="Posts in Last 30 Days"
          sortable
          filter
          dataType="numeric"
          filterType="number"
        />
        <Column
          field="lastPostCreatedAt"
          header="Last Posted On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          filter
          sortable
        />
        <Column
          field="createdAt"
          header="Created On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default GroupsTable;
