import React, { useMemo, useState } from "react";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import DashboardCard from "pages/Dashboard/DashboardCard";
import DashboardSmallCard from "pages/Dashboard/DashboardSmallCard";
import moment from "moment";
import Spinner from "components/Spinner";
import {
  useSummaryByDateQuery,
  useSummaryQuery,
} from "graphql/generated/resourceApi";
import { useClient } from "shared/UserContext";
import { MapViewCollectives } from "components/MapViewCollectives";
import { write, utils, writeFile } from "xlsx";
import { FormEvent, Nullable } from "primereact/ts-helpers";

export const Dashboard: React.FC = () => {
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>([
    moment().subtract(1, "month").toDate(),
    moment().toDate(),
  ]);
  //const [isDarkFromLocalStorage, setIsDarkInLocalStorage] = useLocalStorage<boolean>("isDark", false);
  const client = useClient();
  const { data: queryData, isLoading } = useSummaryQuery(client);
  const navigate = useNavigate();
  const { data: timeQueryData } = useSummaryByDateQuery(client, {
    since: dates?.[0],
    until: dates?.[1]
      ? moment(dates[1]).endOf("day").toDate()
      : moment(dates?.[0]).endOf("day").toDate(),
  });

  console.log('timeQueryData', JSON.stringify(timeQueryData, null, 2));

  const setDateRange = (e: FormEvent<(Date | null)[]>): void => {
    setDates(e.value);
  };
  const setDateAsLastYear = (): void => {
    setDates([moment().subtract(1, "year").toDate(), moment().toDate()]);
  };
  const setDateAsLastMonth = (): void => {
    setDates([moment().subtract(1, "month").toDate(), moment().toDate()]);
  };
  const setDateAsLastWeek = (): void => {
    setDates([moment().subtract(1, "week").toDate(), moment().toDate()]);
  };
  const setDateAsLast24H = (): void => {
    setDates([moment().subtract(1, "day").toDate(), moment().toDate()]);
  };

  const timeQueryDataSection = useMemo(() => {
    if (timeQueryData) {
      const array = Object.keys(timeQueryData).map((key) => {
        // @ts-ignore
        const value = `${timeQueryData[key].aggregate?.count ?? 0}`;
        const tagLabel = "Interval";
        switch (key) {
          case "activeUsersSince":
            return {
              iconClassName: "pi pi-user",
              key: "Active Users",
              value,
              tagLabel,
            };
          case "reactionsSince":
            return {
              iconClassName: "pi pi-heart",
              key: "New Likes",
              value,
              tagLabel,
            };
          case "joinedGroupsSince":
            return {
              iconClassName: "pi pi-users",
              key: "Collectives Joined",
              value,
              tagLabel,
            };
          case "groupsSince":
            return {
              iconClassName: "pi pi-users",
              key: "New Collectives",
              value,
              tagLabel,
            };
          case "postsSince":
            return {
              iconClassName: "pi pi-book",
              key: "New Posts",
              value,
              tagLabel,
            };
          case "postCommentsSince":
            return {
              iconClassName: "pi pi-comment",
              key: "New Comments",
              value,
              tagLabel,
            };
          default:
            return {
              iconClassName: "pi pi-user",
              key: "Users joined",
              value,
              tagLabel,
            };
        }
      });

      return array;
    }
    return [];
  }, [timeQueryData]);

  const totalDataSection = useMemo(() => {
    if (queryData) {
      const array = Object.keys(queryData).map((key) => {
        // @ts-ignore
        const value = `${queryData[key].aggregate?.count ?? 0}`;
        const tagLabel = "Total";
        switch (key) {
          case "usersTotal":
            return {
              iconClassName: "pi pi-user",
              key: "Registered Users",
              onClick: () => navigate("/users"),
              value,
              tagLabel,
            };
          case "groupsTotal":
            return {
              iconClassName: "pi pi-users",
              key: "Collectives",
              onClick: () => navigate("/groups"),
              value,
              tagLabel,
            };
          case "postsTotal":
            return {
              iconClassName: "pi pi-book",
              key: "Posts",
              onClick: () => navigate("/posts"),
              value,
              tagLabel,
            };
          case "postCommentsTotal":
            return {
              iconClassName: "pi pi-comment",
              key: "Comments",
              onClick: () => navigate("/posts"),
              value,
              tagLabel,
            };
          default:
            return undefined;
        }
      });

      return array;
    }
    return [];
  }, [queryData]);

  const exportCSV = () => {
    console.log("exportCSV");
    const dataToDownload = [...totalDataSection, ...timeQueryDataSection].map(
      (item) => {
        console.log(item);
        if (item) {
          return {
            key: item.key,
            value: item.value,
            start: item.tagLabel === "Total" ? "" : dates?.[0]?.toLocaleDateString(),
            end: item.tagLabel === "Total" ? new Date().toLocaleDateString() : dates?.[1]?.toLocaleDateString(),
          }
        }
        return {};
      }
        
    );
    const worksheet = utils.json_to_sheet(dataToDownload);
    let workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "sheet");
    write(workbook, { bookType: "csv", type: "buffer" });
    write(workbook, { bookType: "csv", type: "binary" });
    writeFile(workbook, "dashboard-exported-data.csv");
  };

  if (isLoading) return <Spinner />;
  if (!queryData) return null;

  /*const onThemeChange = (e: ToggleButtonChangeParams): void => {
    setIsDarkInLocalStorage(e.value);

    if (e.value) {
      Object.keys(darkTheme).forEach((key) => {
        document.documentElement.style.setProperty(key, darkTheme[key]);
      });
    } else {
      Object.keys(lightTheme).forEach((key) => {
        document.documentElement.style.setProperty(key, lightTheme[key]);
      });
    }
  };*/

  return (
    <div className="grid grid-cols-12 gap-6 m-2">
      {totalDataSection.length > 0
        ? totalDataSection.map((item) =>
            item ? (
              <DashboardCard
                key={item.key}
                iconClassName={item.iconClassName}
                tagLabel={item.tagLabel}
                mainText={item.value}
                secondaryText={item.key}
                onClick={item.onClick}
              />
            ) : null
          )
        : null}
      <div className="col-span-12 xl:col-span-3 flex flex-col gap-6">
        <div className="transform w-full hover:scale-105 transition duration-300 shadow-xl rounded-lg">
          <Calendar
            className="w-full border-0"
            value={dates}
            onChange={setDateRange}
            selectionMode="range"
            maxDate={new Date()}
            numberOfMonths={2}
            footerTemplate={() => (
              <span className="p-buttonset">
                <Button
                  className="p-button-text p-button-secondary"
                  label="In The Last 24H"
                  onClick={setDateAsLast24H}
                />
                <Button
                  className="p-button-text p-button-secondary"
                  label="In The Last Week"
                  onClick={setDateAsLastWeek}
                />
                <Button
                  className="p-button-text p-button-secondary"
                  label="In The Last Month"
                  onClick={setDateAsLastMonth}
                />
                <Button
                  className="p-button-text p-button-secondary"
                  label="In The Last Year"
                  onClick={setDateAsLastYear}
                />
              </span>
            )}
            showOtherMonths
            showIcon
          />
        </div>
        {timeQueryDataSection.length > 0 ? (
          <>
            {timeQueryDataSection.map((item: any, index) => (
              <DashboardSmallCard
                key={index}
                iconClassName={item.iconClassName}
                mainText={item.value}
                secondaryText={item.key}
              />
            ))}
          </>
        ) : (
          <Spinner />
        )}
      </div>
      <div
        className="col-span-12 xl:col-span-9 transform transition duration-300 rounded-lg overflow-hidden shadow-xl"
        style={{ minHeight: "300px" }}
      >
        <MapViewCollectives />
      </div>
      <div>
        <Button
          onClick={exportCSV}
          tooltip="Export CSV"
          tooltipOptions={{ position: "left" }}
        >
          <i className="pi pi-file" />
        </Button>
      </div>
    </div>
  );
};
