const config = {
  auth0: {
    domain: "auth.thelitas.co",
    clientId: "4b7LrqmXJqvZiqRdd4lNjMlIq9JnTTRo",
    audience: "https://auth.thelitas.co",
  },
  baseUrl: "https://files.thelitas.co",
  jwtClaimsKey: "https://hasura.io/jwt/claims",
  graphqlUrl: "https://api.thelitas.co/v1/graphql",
  logoUrl:
    "https://images.squarespace-cdn.com/content/5476c083e4b00022a5334b87/1574303875396-HDXUP20DZ6T9XNQV5AJC/The+Litas+Logo+Black_NEW.png?format=500w&content-type=image%2Fpng",
  geocoderApi:
    "AAPK106943cf591d44eca2c185fa471f5b65QHXL_UMxtoM5-gukHPepT_xhYVUsUwN3ETAPtYT4Hrgr4R0uhLqiaPUG55P8vOS9",
};

export default config;
