const paths = {
  root: "/",
  error: "/error",
  general: {
    home: "/dashboard",
    about: "/about",
    validate: "/validate-token",
    notFound: "/not-found",
    styleSamples: "/style-samples",
  },
  users: {
    root: "/users",
  },
  posts: {
    root: "/posts",
  },
  homePosts: {
    root: "/home-posts",
  },
  pinnedPosts: {
    root: "/pinned-posts",
  },
  reportedPosts: {
    root: "/reported-posts",
  },
  postReports: {
    root: "/post-reports",
  },
  hiddenPosts: {
    root: "/hidden-posts",
  },
  groups: {
    root: "/groups",
  },
  groupRequests: {
    root: "/group-requests",
  },
  featurebaseTest: {
    root: "/featurebase-test",
  },
  bikes: {
    root: "/bikes",
    detail: "/bikes/:id",
  },
  devices: {
    root: "/devices",
  },
};
export default paths;
