import React, { useEffect, useState } from "react";
import { GroupFieldsFragment } from "graphql/generated/resourceApi";
import DetailTable from "components/Detail/DetailTable";
import { Image } from "primereact/image";
import GroupEdit from "./GroupEdit";
import { useAuthToken } from "shared/UserContext";

interface GroupDetailProps {
  group?: GroupFieldsFragment;
  showEdit?: boolean;
  onUpdate: () => void;
}

const GroupDetail: React.FC<GroupDetailProps> = ({
  group,
  showEdit,
  onUpdate,
}) => {
  const [showEditGroupDialog, setShowEditGroupDialog] = useState(false);
  const [currentGroup, setCurrentGroup] = useState<
    GroupFieldsFragment | undefined
  >(group);

  const token = useAuthToken();
  const url = currentGroup?.image
    ? `${currentGroup.image.url}&token=${token}`
    : undefined;

  const onComplete = (updatedGroup?: GroupFieldsFragment) => {
    setCurrentGroup(updatedGroup);
    setShowEditGroupDialog(false);
    onUpdate();
  };

  const title = currentGroup?.name;

  useEffect(() => {
    setShowEditGroupDialog(showEdit || false);
    if (group) {
      setCurrentGroup(group);
    }
  }, [showEdit, group]);

  return (
    <div>
      <GroupEdit
        group={currentGroup}
        onComplete={onComplete}
        showEditGroupDialog={showEditGroupDialog}
        setShowEditGroupDialog={setShowEditGroupDialog}
      />
      <div className="flex flex-col-reverse lg:flex-row justify-center gap-6">
        <div className="w-full lg:w-4/5">
          {currentGroup && (
            <>
              <DetailTable
                title={title}
                data={currentGroup}
                fields={{
                  name: { label: "name", value: "name" },
                  description: { label: "description", value: "description" },
                  "userGroupsAggregate.aggregate.count": {
                    label: "Users",
                    value: "userGroupsAggregate.aggregate.count",
                  },
                  posts: {
                    label: "Posts",
                    value: "postsAggregate.aggregate.count",
                  },
                  hidden: {
                    label: "Hidden",
                    value: "isHidden",
                    override: (value) => {
                      return value ? "Yes" : "No";
                    },
                  },
                }}
                onEditButtonClick={() => setShowEditGroupDialog(true)}
              />
            </>
          )}
        </div>
        <div className="w-full lg:w-1/5" style={{ margin: "16px auto" }}>
          <Image
            className="flex justify-center"
            imageStyle={{ maxHeight: "288px" }}
            src={url ?? undefined}
            alt="Group Avatar"
            preview={!!url}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupDetail;
