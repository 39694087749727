export const darkTheme: { [key: string]: string } = {
  '--primary-color': '#00cfe5',
  '--primary-color-hover': '#12c2e9b3',
  '--primary-color-text': '#ffffff',
  '--text-color': '#ffffff',
  '--text-color-secondary': '#717171',
  '--error': '#e24c4c',
  '--surface-header': '#1e1e1e',
  '--surface-content': '#080717',
  '--surface-border': '#383838',
  '--surface-section': '#18191d',
  '--toolbar-background': '#1e1e1e',
  '--border-radius': '3px',
  '--disabled-opacity': '0.6',
  '--highlight-background': '#383838',
  '--text-on-highlight': '#ffffff',
  '--hover-background': '#383838',
  '--focus-ring': '#d0f1ee',
  '--form-background': '#1b1a1a',
  '--form-border': '#383838',
  '--form-error-border': '#f0a9a7',
  '--form-hover-border': '#12c2e9b3',
  '--form-focus-border': '#12c2e9b3',
  '--form-filled-background': '#383838',
  '--buttons-primary-hover': '#12c2e9b3',
  '--buttons-secondary-background': '#5A5858',
  '--buttons-secondary-text': '#FFFFFF',
  '--buttons-success-background': '#009688',
  '--buttons-success-text': '#FFFFFF',
  '--buttons-info-background': '#3B82F6',
  '--buttons-info-text': '#FFFFFF',
  '--buttons-warning-background': '#F59E0B',
  '--buttons-warning-text': '#FFFFFF',
  '--buttons-help-background': '#A855F7',
  '--buttons-help-text': '#FFFFFF',
  '--buttons-danger-background': '#EF4444',
  '--buttons-danger-text': '#FFFFFF',
  '--buttons-border': '#383838',
  '--messages-info': '#EFF6FF',
  '--messages-success': '#ECFCF5',
  '--messages-warning': '#FEF3F7',
  '--messages-error': '#FEF3F7',
  '--header-background': '#383838',
  '--crwdid-color': '#c120db',
  '--crwdworld-color': '#671ecd',
  '--crwdmarket-color': '#e50a72',
  '--crwdcapital-color': '#00cfe5',
  '--crwdfinance-color': '#005ce5'
}
export const lightTheme: { [key: string]: string } = {
  '--primary-color': '#1565C0',
  '--primary-color-hover': '#315DAC',
  '--primary-color-text': '#ffffff',
  '--text-color': '#495057',
  '--text-color-secondary': '#6c757d',
  '--error': '#e24c4c',
  '--surface-header': '#f5f5f5',
  '--surface-content': '#dadee3',
  '--surface-border': '#383838',
  '--surface-section': '#ebe3e3',
  '--toolbar-background': '#f5f5f5',
  '--border-radius': '3px',
  '--disabled-opacity': '0.6',
  '--highlight-background': '#b4b4b4',
  '--text-on-highlight': '#ffffff',
  '--hover-background': '#eeeeee',
  '--focus-ring': '#82b6ff',
  '--form-background': '#ffffff',
  '--form-border': '#383838',
  '--form-error-border': '#f0a9a7',
  '--form-hover-border': '#1269ea',
  '--form-focus-border': '#063F97',
  '--form-filled-background': '#383838',
  '--buttons-primary-hover': '#1269ea',
  '--buttons-secondary-background': '#5A5858',
  '--buttons-secondary-text': '#ffffff',
  '--buttons-success-background': '#005ce5',
  '--buttons-success-text': '#ffffff',
  '--buttons-info-background': '#3B82F6',
  '--buttons-info-text': '#ffffff',
  '--buttons-warning-background': '#F59E0B',
  '--buttons-warning-text': '#ffffff',
  '--buttons-help-background': '#A855F7',
  '--buttons-help-text': '#ffffff',
  '--buttons-danger-background': '#EF4444',
  '--buttons-danger-text': '#ffffff',
  '--buttons-border': '#e5e7eb',
  '--messages-info': '#EFF6FF',
  '--messages-success': '#ECFCF5',
  '--messages-warning': '#FEF3F7',
  '--messages-error': '#FEF3F7',
  '--header-background': '#383838',
  '--crwdid-color': '#c120db',
  '--crwdworld-color': '#671ecd',
  '--crwdmarket-color': '#e50a72',
  '--crwdcapital-color': '#00cfe5',
  '--crwdfinance-color': '#005ce5'
}
