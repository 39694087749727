import React, { useMemo, useRef, useState } from "react";
import { PostAdminTableFieldsFragment } from "graphql/generated/resourceApi";
import { Image } from "primereact/image";
import PostEdit from "./PostEdit";
import DetailRow from "components/Detail/DetailRow";
import { Button } from "primereact/button";
import { formatTextWithMentions } from "utils/postUtils";
import { Dialog } from "primereact/dialog";
import { Editor } from "primereact/editor";
import { useUserContext } from "shared/UserContext";
import { Toast } from "primereact/toast";
interface PostDetailProps {
  post: PostAdminTableFieldsFragment;
  onUpdate: ({
    title,
    message,
    post,
  }: {
    title: string;
    message: string;
    post?: PostAdminTableFieldsFragment;
  }) => void;
}

const PostDetail: React.FC<PostDetailProps> = ({ post, onUpdate }) => {
  const { sdkClient } = useUserContext();
  const [editPostVisible, setEditPostVisible] = useState(false);
  const [currentPost, setCurrentPost] =
    useState<PostAdminTableFieldsFragment>(post);
  const [loadingPinPost, setLoadingPinPost] = useState(false);
  const isPinned = useMemo(() => !!currentPost.pinnedAt, [currentPost]);
  const toast = useRef<Toast>(null);

  const title = `${
    currentPost.user?.name ?? currentPost.user?.primaryEmail
  } post`;

  const pinPost = async ({ unpin }: { unpin: boolean } = { unpin: false }) => {
    setLoadingPinPost(true);

    const pinPostResult = await sdkClient.pinPost({
      args: { postId: currentPost.id, unpin: unpin },
    });
    console.log(pinPostResult);
    if (pinPostResult.pinPost?.isError) {
      toast.current?.show({
        severity: "error",
        summary: "Pin Post",
        detail: pinPostResult.pinPost.message,
        sticky: true,
        // life: 10000,
        closable: true,
      });
      setLoadingPinPost(false);
      return;
    }

    const updatedPostResult = await sdkClient.postByIdAdmin({
      id: currentPost.id,
    });
    if (updatedPostResult.postById) {
      setCurrentPost(updatedPostResult.postById);
      // onUpdate({
      //   title: "Pin Post",
      //   message: pinPostResult.pinPost?.message ?? "",
      //   post: updatedPostResult.postById,
      // });
    }

    setLoadingPinPost(false);
  };

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={editPostVisible}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setEditPostVisible(false)}
        header="Edit Post"
      >
        <PostEdit
          post={currentPost}
          onUpdate={(params) => {
            if (params.post) {
              setCurrentPost(params.post);
            }
            onUpdate(params);
          }}
        />
      </Dialog>

      <div className="flex flex-col-reverse lg:flex-row justify-center gap-6">
        <div className="w-full lg:w-4/5">
          <div
            className="py-1 mb-2 font-semibold text-xl border-solid border-b-2
        p-primary-border-color w-full flex flex-row items-center justify-between"
          >
            <div className="inline-block overflow-hidden text-ellipsis whitespace-nowrap">
              <i
                className="pi pi-book mr-3 p-text-secondary"
                style={{ fontSize: "1.4em" }}
              />
              {title}
            </div>
            <div>
              <Button
                label={isPinned ? "Unpin" : "Pin"}
                icon={isPinned ? "pi pi-thumbtack" : "pi pi-thumbtack"}
                className="p-button-text text-primary"
                onClick={() => pinPost({ unpin: isPinned })}
                loading={loadingPinPost}
              />
              <Button
                label="Edit"
                icon="pi pi-pencil"
                className="p-button-text text-primary"
                onClick={() => setEditPostVisible(true)}
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-between items-center gap-4">
            <table className="text-m">
              <tbody>
                <DetailRow
                  name="Body"
                  value={formatTextWithMentions(currentPost.body)}
                />
                <DetailRow
                  name="Collective"
                  value={currentPost.group?.name ?? "The Litas Collective"}
                />
                <DetailRow
                  name="Shadow Banned"
                  value={currentPost.isSilentlyHidden ? "Yes" : "No"}
                />
                <DetailRow
                  name="Hidden"
                  value={currentPost.isHiddenByReport ? "Yes" : "No"}
                />
                <DetailRow name="User Name" value={currentPost.user?.name} />
                <DetailRow
                  name="User Email"
                  value={currentPost.user?.primaryEmail}
                />
                <DetailRow
                  name="Pinned By"
                  value={currentPost.pinnedBy?.primaryEmail}
                />
                <DetailRow
                  name="Pinned On"
                  value={
                    currentPost.pinnedAt &&
                    new Date(currentPost.pinnedAt).toLocaleDateString()
                  }
                />
                <DetailRow
                  name="Created On"
                  value={
                    currentPost.createdAt &&
                    new Date(currentPost.createdAt).toLocaleDateString()
                  }
                />
              </tbody>
            </table>
          </div>
          <div className="text-l my-2 font-semibold">Notes:</div>
          <Editor
            value={currentPost.metadata.notes}
            readOnly
            showHeader={false}
            style={{ height: "300px" }}
          />
        </div>
        <div className="w-full lg:w-1/5" style={{ margin: "16px auto" }}>
          <Image
            className="flex justify-center"
            imageStyle={{ maxHeight: "288px" }}
            src={currentPost?.postImages[0]?.file.url ?? undefined}
            alt="Post Avatar"
            preview={!!currentPost?.postImages[0]?.file.url}
          />
        </div>
      </div>
    </div>
  );
};

export default PostDetail;
