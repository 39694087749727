import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'
import './Spinner.scss'

interface SpinnerProps {
  className?: string
  text?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ className, text }) => (
  <div className={className ? `loader ${className}` : 'loader'}>
    <div className="loader--content">
      <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="transparent" />
      {text && <span className="p-text-primary">{text}</span>}
    </div>
  </div>
)
