import React, { useEffect, useState } from "react";
import { InputText, InputTextProps } from "primereact/inputtext";
import useDebounce from "hooks/useDebounce";

type InputTextDebouncedProps = InputTextProps &
  React.RefAttributes<HTMLInputElement> & {
    onChangeTextDebounced?: (text: string) => void;
    debounceTime?: number;
  };

const InputTextDebounced: React.FC<InputTextDebouncedProps> = (props) => {
  const {onChangeTextDebounced, debounceTime, ...rest} = props;
  const [internalValue, setInternalValue] = useState("");
  const debounce = useDebounce(debounceTime ?? 600);
  

  useEffect(() => {
    setInternalValue(props.value ?? "");
  }, [props.value]);

  useEffect(() => {
    let isMounted = true;
    debounce(() => {
      if (isMounted) {
        onChangeTextDebounced?.(internalValue);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [internalValue]);

  return (
    <InputText
      {...rest}
      value={internalValue}
      onChange={(e) => {
        setInternalValue(e.target.value);
        props.onChange?.(e);
      }}
    />
  );
};

export default InputTextDebounced;
