import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import { GraphQLClient } from "graphql-request";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProps, useAuth } from "react-oidc-context";
import log from "log";
import {
  getSdk,
  Sdk,
  UserFieldsFragment,
} from "graphql/generated/graphqlRequest";
import { useUserByIdQuery } from "graphql/generated/resourceApi";
import { RequestMiddleware } from "graphql-request/build/esm/types";

const graphqlUrl = import.meta.env.VITE_APP_GRAPHQL_URL as string;
const siteUrl = import.meta.env.VITE_APP_SITE_URL as string;
const queryClient = new QueryClient();

const debug = true;

const graphqlRequestMiddleware = (
  auth?: AuthContextProps
): RequestMiddleware => {
  return async (request) => {
    if (debug && typeof request.body === "string") {
      const body = JSON.parse(request.body);
      log.debug("graphql", body.operationName, JSON.stringify(body.variables));
    }

    if (auth?.user?.expired) {
      auth.signoutRedirect({
        extraQueryParams: {
          redirect_uri: siteUrl,
        },
      });
    }
    return request;
  };
};

export function clientWithToken(
  token?: string,
  auth?: AuthContextProps
): GraphQLClient {
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
  return new GraphQLClient(graphqlUrl, {
    headers,
    requestMiddleware: graphqlRequestMiddleware(auth),
  });
}

export function sdkClientWithToken(
  token?: string,
  auth?: AuthContextProps
): Sdk {
  return getSdk(clientWithToken(token, auth));
}

export interface UserContextType {
  token: string;
  userId: string;
  userRole: string;
  client: GraphQLClient;
  sdkClient: Sdk;
  logout: () => void;
}

const initialContext = {
  token: "",
  userId: "",
  userRole: "",
  client: clientWithToken(),
  sdkClient: sdkClientWithToken(),
  logout: () => {},
};

export interface UseCurrentUserResult {
  user?: UserFieldsFragment;
  isUserLoading: boolean;
}

export const UserContext = createContext<UserContextType>(initialContext);

export const useUserContext = (): UserContextType => useContext(UserContext);
export const useClient = (): GraphQLClient => useUserContext().client;
export const useAuthToken = (): string => useUserContext().token;
export const useCurrentUser = (): UseCurrentUserResult => {
  const { client, userId } = useUserContext();
  const userState = useUserByIdQuery(client, {
    id: userId,
  });

  return {
    user: userState.data?.userById || undefined,
    isUserLoading: userState.isLoading,
  };
};

const { VITE_APP_JWT_CLAIMS_KEY: jwtClaimsKey } = import.meta.env;

export function userIdFromToken(token?: string): string {
  if (!token) {
    return "";
  }
  return jwtDecode<Record<string, any>>(token)[jwtClaimsKey][
    "x-hasura-user-id"
  ];
}

export function userRoleFromToken(token?: string): string {
  if (!token) {
    return "";
  }
  return jwtDecode<Record<string, any>>(token)[jwtClaimsKey][
    "x-hasura-default-role"
  ];
}

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | undefined>();
  const auth = useAuth();

  // Sign in redirect is now in Protected Layout

  const logout = (): void => {
    auth.signoutRedirect({
      extraQueryParams: {
        redirect_uri: siteUrl,
      },
    });
  };

  useEffect(() => {
    if (token !== auth.user?.id_token) {
      setToken(auth.user?.id_token);
    }
  }, [auth, token]);

  const userContextValue: UserContextType = useMemo(
    () => ({
      token: token ?? "",
      userId: userIdFromToken(token),
      userRole: userRoleFromToken(token),
      client: clientWithToken(token, auth),
      sdkClient: sdkClientWithToken(token, auth),
      logout,
    }),
    [token, logout]
  );

  return (
    <UserContext.Provider value={userContextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </UserContext.Provider>
  );
};
