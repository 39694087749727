import { useEffect, useState } from "react";

export function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useLayoutDimensions() {
  const toolbarHeight = 56;
  const sidebarWidth = 240;
  const outletPadding = 20;
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 960);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsDesktop(windowDimensions.width > 960);
  }, [windowDimensions.width]);

  return {
    windowWidth: windowDimensions.width,
    windowHight: windowDimensions.height,
    outletWidth: windowDimensions.width - (isDesktop ? sidebarWidth : 0),
    outletHeight: windowDimensions.height - toolbarHeight,
    outletPadding,
    sidebarWidth,
    toolbarHeight,
    isDesktop,
  };
}
