import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { reverseGeocoder } from "../../utils/geoCoder/geoCoderClient";
import { getByIso3 } from "../../utils/geoCoder/getByIso3";
import { classNames } from "primereact/utils";
import "./leaflet.css";

import EsriLeafletGeoSearch from "react-esri-leaflet/plugins/EsriLeafletGeoSearch";
import { LatLng } from "leaflet";
import { Marker, useMapEvents } from "react-leaflet";
import { MapContainer, TileLayer } from "react-leaflet";
import { collectiveIcon } from "utils/leaflet/collectiveIcon";
import "leaflet/dist/leaflet.css";
import config from "config";


export interface Location {
  name?: string;
  latitude: number;
  longitude: number;
}



interface DynamicMarkerProps {
  coordinates?: { lat: number | undefined; lng: number | undefined };
  onChangeCoordinates: (coordinates: LatLng) => void;
}

export const DynamicLeafletMarker = ({
  coordinates,
  onChangeCoordinates,
}: DynamicMarkerProps) => {
  useMapEvents({
    click: async (e) => {
      onChangeCoordinates(e.latlng);
    },
  });
  return (
    <>
      {coordinates && coordinates.lat && coordinates.lng && (
        <Marker
          key={`marker-${coordinates.lat}-${coordinates.lng}`}
          position={[coordinates.lat, coordinates.lng]}
          icon={collectiveIcon}
        />
      )}
    </>
  );
};

interface LocationFormProps {
  location?: Location;
  visible: boolean;
  onHide: () => void;
  setLocation: (location: Location) => void;
  toastRef?: any;
}

export const LocationForm: React.FC<LocationFormProps> = ({
  location,
  visible,
  onHide,
  setLocation,
}) => {
  const [loading] = useState({ state: false, message: "" });
  const [coordinates, setCoordinates] = useState<{
    lat: number | undefined;
    lng: number | undefined;
  }>({
    lat: location?.latitude,
    lng: location?.longitude,
  });
  const [geoLocation, setGeoLocation] = useState<Location>({
    name: "",
    latitude: 0,
    longitude: 0,
  });

  const setLatitude = (lat?: number | null) => {
    if (lat) setCoordinates((previous) => ({ ...previous, lat }));
  };
  const setLongitude = (lng?: number | null) => {
    if (lng) setCoordinates((previous) => ({ ...previous, lng }));
  };

  const geocoder = async (coord: { lat: number; lng: number }) => {
    setGeoLocation({
      latitude: coord.lat,
      longitude: coord.lng,
    });
    try {
      const data = await reverseGeocoder(coord.lat, coord.lng);
      const country = getByIso3(data.CountryCode);
      setGeoLocation({
        latitude: coord.lat,
        longitude: coord.lng,
        name: `${data.City}, ${data.Region}, ${country.name}`,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const geocoderSetCoordinates = (coord: { lat: number; lng: number }) => {
    setCoordinates(coord);
    geocoder(coord);
  };

  const geocoderWithCoordinatesState = () => {
    if (coordinates.lat !== undefined && coordinates.lng)
      geocoder({ lat: coordinates.lat, lng: coordinates.lng });
  };

  return (
    <>
      <Dialog
        header="Edit Location"
        visible={visible}
        style={{ width: "70vw" }}
        onHide={onHide}
      >
        <div className="pl-6 pr-6 pt-4">
          <Accordion activeIndex={1}>
            <AccordionTab header="Defines a location using coordinates">
              <div className="grid grid-cols-3 gap-x-12 justify-items-stretch p-4">
                <div className="p-float-label">
                  <InputNumber
                    id="input-latitude"
                    className="p-inputtext-sm p-d-block p-mb-2 w-full"
                    value={coordinates.lat ?? 0}
                    onChange={(e) => setLatitude(e.value)}
                  />
                  <label htmlFor="input-latitude">Latitude</label>
                </div>
                <div className="p-float-label">
                  <InputNumber
                    id="input-longitude"
                    className="p-inputtext-sm p-d-block p-mb-2 w-full"
                    value={coordinates.lng ?? 0}
                    onChange={(e) => setLongitude(e.value)}
                  />
                  <label htmlFor="input-longitude">Longitude</label>
                </div>
                <Button
                  label="Search Location"
                  type="button"
                  onClick={geocoderWithCoordinatesState}
                />
              </div>
            </AccordionTab>
            <AccordionTab header="Defines a location using the map or the integrated search">
              <MapContainer
                center={[coordinates?.lat || 40, coordinates?.lng || -100]}
                zoom={3}
                scrollWheelZoom={false}
                style={{ width: "100%", height: 400 }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <EsriLeafletGeoSearch
                  useMapBounds={false}
                  position="topright"
                  providers={{
                    arcgisOnlineProvider: {
                      apikey: config.geocoderApi,
                      label: "ArcGIS Online Results",
                      maxResults: 10,
                    },
                  }}
                  eventHandlers={{
                    results: (data: any) => {
                      if (
                        data.results[0].latlng &&
                        data.results[0].latlng.lat &&
                        data.results[0].latlng.lng
                      ) {
                        geocoderSetCoordinates(data.results[0].latlng);
                      }
                    },
                  }}
                />
                <DynamicLeafletMarker
                  coordinates={coordinates}
                  onChangeCoordinates={geocoderSetCoordinates}
                />
              </MapContainer>
            </AccordionTab>
          </Accordion>
        </div>
        <div className="flex flex-col gap-4 mt-8">
          <div className="p-float-label w-full">
            <InputNumber
              name="latitude"
              required
              className="w-full"
              //label="Latitude"
              value={geoLocation.latitude ?? 0}
              onChange={(e) => {
                setGeoLocation((prev) => ({
                  ...prev,
                  latitude: e.value ?? 0,
                }));
              }}
            />
            <label htmlFor="input-latitude">Latitude</label>
          </div>
          <div className="p-float-label w-full">
            <InputNumber
              name="longitude"
              required
              className="w-full"
              value={geoLocation.longitude ?? 0}
              onChange={(e) => {
                setGeoLocation((prev) => ({
                  ...prev,
                  longitude: e.value ?? 0,
                }));
              }}
            />
            <label htmlFor="input-latitude">Longitude</label>
          </div>
          <div className="p-float-label w-full">
            <InputText
              name="name"
              required
              //label="Name"
              value={geoLocation.name ?? ""}
              className="w-full"
              onChange={(e) => {
                setGeoLocation((prev) => ({
                  ...prev,
                  name: e.currentTarget.value ?? undefined,
                }));
              }}
            />
            <label htmlFor="input-latitude">Name</label>
          </div>
          <Button
            label={"Update Location"}
            className={classNames("p-button-primary", {
              hidden: loading.state,
            })}
            type="button"
            onClick={() => {
              setLocation?.(geoLocation);
              onHide();
            }}
          />
        </div>
        {/*<FlexForm
          config={HasuraConfig.location}
          grid={{
            columnCount: 1,
          }}
          onSuccess={(data: any) => {
            if (location?.id === undefined && setLocationId)
              setLocationId(data.insert_location_one.id);
            onHide();
          }}
          data={location}
          fields={[
            "latitude",
            "longitude",
            "name",
            "city",
            "state",
            "country",
            "countryCode",
            "formattedAddress",
          ]}
          components={{
            latitude: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.latitude}
              />
            ),
            longitude: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.longitude}
              />
            ),
            name: (props) => (
              <FlexFormInputTextWithValue {...props} value={geoLocation.name} />
            ),
            city: (props) => (
              <FlexFormInputTextWithValue {...props} value={geoLocation.city} />
            ),
            state: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.state}
              />
            ),
            country: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.country}
              />
            ),
            countryCode: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.countryCode}
              />
            ),
            formattedAddress: (props) => (
              <FlexFormInputTextWithValue
                {...props}
                value={geoLocation.formattedAddress}
              />
            ),
          }}
          isNew={location?.id === undefined}
        />*/}
      </Dialog>
    </>
  );
};
