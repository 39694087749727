import React from "react";
import { TabPanel, TabView } from "primereact/tabview";
import {
  BikeByIdQuery,
  BikeFieldsFragment,
} from "graphql/generated/resourceApi";
import { UseQueryResult } from "@tanstack/react-query";
import BikeDetail from "./BikeDetail";

interface BikeTabsProps {
  bike: BikeFieldsFragment;
  bikeState?: UseQueryResult<BikeByIdQuery, unknown>;
}

const BikeTabs: React.FC<BikeTabsProps> = ({ bike, bikeState }) => {
  return (
    <TabView style={{ padding: "0 1rem" }}>
      <TabPanel header="Details">
        <BikeDetail bike={bike} bikeState={bikeState} />
      </TabPanel>
    </TabView>
  );
};

export default BikeTabs;
