interface DetailRowProps {
  name: string;
  value?: string | null;
  defaultValue?: string;
}

const DetailRow: React.FC<DetailRowProps> = ({ name, value, defaultValue }) => {
  const displayValue = (value || defaultValue) ?? "-";
  return (
    <tr className="m-1">
      <td className="font-semibold p-text-secondary">{name}</td>
      <td className={`pl-3 max-w-xl whitespace-pre-wrap ${!value ? "italic" : null}`}>
        {displayValue}
      </td>
    </tr>
  );
};

export default DetailRow;
