/* eslint-disable no-await-in-loop */
import React, { useState, useRef, useEffect } from "react";
import {
  AdminTable,
  AdminTableState,
  booleanFilterTemplate,
  dateBody,
  dateFilterTemplate,
} from "components/Admin";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { FilterOperator, FilterMatchMode } from "primereact/api";
import { Toast } from "primereact/toast";
import { DeviceAdminTableFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useDataAdapter } from "hooks/useDataAdapter";
import UserTabs, {
  dialogWithTabsTableHeightOffset,
} from "components/User/UserTabs";
import { roleFilterElement, yearsRidingBody } from "components/User/UsersTable";

export interface DevicesTableProps {
  userId?: string;
  disableRowButton?: boolean;
  tableStateOverride?: Partial<AdminTableState>;
  onTableStateEvent?: (e: AdminTableState) => void;
  hideHeader?: boolean;
  tableHeight?: number;
}

const DevicesTable: React.FC<DevicesTableProps> = ({
  userId,
  disableRowButton,
  tableStateOverride,
  onTableStateEvent,
  hideHeader,
  tableHeight,
}) => {
  const dialogRef = useRef<Dialog>(null);
  const [dialogHeight, setDialogHeight] = useState(0);
  const toast = useRef<Toast>(null);
  const { tableAdapter } = useDataAdapter(
    "device",
    DeviceAdminTableFieldsFragmentDoc,
    userId
      ? {
          userId: { _eq: userId },
        }
      : undefined
  );
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [deviceUserId, setDeviceUserId] = useState<string>();

  const initialFilters = {
    os: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    osVersion: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    brand: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    model: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    notificationsEnabled: { value: null, matchMode: FilterMatchMode.EQUALS },
    appVersion: {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.primaryEmail": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.firstName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    "user.lastName": {
      operator: FilterOperator.OR,
      constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
    },
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["model", "brand", "os", "user.name"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
    ...tableStateOverride,
  });
  const handleOnStateEvent = (state: AdminTableState) => {
    setTableState(state);
    onTableStateEvent?.(state);
  };
  useEffect(() => {
    setTableState((previousTableState) => ({
      ...previousTableState,
      ...tableStateOverride,
    }));
  }, [tableStateOverride]);

  return (
    <div>
      <Toast ref={toast} />
      <Dialog
        visible={showUserModal}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowUserModal(false)}
        ref={dialogRef}
        onResize={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
        onShow={() => {
          setDialogHeight(dialogRef.current?.getContent()?.clientHeight ?? 0);
        }}
      >
        <UserTabs
          userId={deviceUserId}
          activeTab="devices"
          tableHeight={dialogHeight - dialogWithTabsTableHeightOffset}
          onUpdate={() => {}}
        />
      </Dialog>

      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-mobile text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Devices</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={handleOnStateEvent}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search device os, brand, model, or user name"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        onRowSelect={(e: DataTableSelectEvent) => {
          if (!disableRowButton) {
            setDeviceUserId(e.data.userId);
            setShowUserModal(true);
          }
        }}
        tableHeight={tableHeight}
        defaultFieldsSelected={[
          "user.primaryEmail",
          "user.firstName",
          "user.lastName",
          "os",
          "osVersion",
          "brand",
          "model",
          "appVersion",
          "notificationsEnabled",
          "createdAt",
        ]}
      >
        <Column
          field="user.primaryEmail"
          header="User Email"
          sortable
          filter
          style={{
            minWidth: "300px",
          }}
        />
        <Column
          field="user.firstName"
          header="User First Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column
          field="user.lastName"
          header="User Last Name"
          filter
          style={{
            minWidth: "200px",
          }}
        />
        <Column field="os" header="OS" sortable filter />
        <Column field="osVersion" header="OS Version" sortable filter />
        <Column
          field="brand"
          header="Brand"
          sortable
          filter
          style={{
            minWidth: "150px",
          }}
        />
        <Column
          field="model"
          header="Model"
          sortable
          filter
          style={{
            minWidth: "150px",
          }}
        />
        <Column field="appVersion" header="App Version" sortable filter />
        <Column
          field="notificationsEnabled"
          header="Notifications"
          sortable
          filter
          style={{
            minWidth: "150px",
          }}
          dataType="boolean"
          filterElement={booleanFilterTemplate}
        />
        <Column
          field="user.attributes.hasuraRole"
          header="Role"
          filterElement={roleFilterElement}
          // filter
        />
        <Column
          field="user.postsCount"
          header="Posts"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.groupsCount"
          header="Groups"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.blocksCount"
          header="Blocked By"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.bikesCount"
          header="Bikes"
          dataType="numeric"
          filterType="number"
          // filter
        />
        <Column
          field="user.homeLocation.name"
          header="Location"
          style={{
            minWidth: "200px",
          }}
          sortable
          // filter
        />
        <Column
          field="user.attributes.pronouns"
          header="Pronouns"
          // filter
        />
        <Column
          field="user.attributes.phoneNumber"
          header="Phone Number"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.dateOfBirth"
          header="Birth Date"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
        />
        <Column
          field="user.attributes.ridingSince"
          header="Years Riding"
          body={yearsRidingBody}
        />
        <Column
          field="user.attributes.website"
          header="Website"
          style={{
            minWidth: "200px",
          }}
          // filter
        />
        <Column
          field="user.attributes.lastActivity"
          header="Last Activity"
          body={dateBody}
          dataType="date"
        />
        <Column
          field="user.attributes.instagramLink"
          header="IG Handle"
          style={{
            minWidth: "150px",
          }}
          filter
        />
        <Column
          field="createdAt"
          header="Created On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default DevicesTable;
