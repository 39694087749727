import React from "react";
import {
  GroupFieldsFragment,
} from "graphql/generated/resourceApi";
import GroupDetailForm from "./GroupDetailForm";
import { Dialog } from "primereact/dialog";

interface GroupEditProps {
  group?: GroupFieldsFragment;
  onComplete: (group?: GroupFieldsFragment) => void;
  showEditGroupDialog?: boolean;
  setShowEditGroupDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupEdit: React.FC<GroupEditProps> = ({
  group,
  onComplete,
  showEditGroupDialog,
  setShowEditGroupDialog,
}) => {
  return (
    <Dialog
        visible={showEditGroupDialog}
        breakpoints={{ "960px": "75vw" }}
        modal
        style={{ width: "75vw", height: "75vh" }}
        onHide={() => setShowEditGroupDialog?.(false)}
        header="Edit Collective"
      >
       <div className="p-3">
            <GroupDetailForm
              group={group}
              onComplete={onComplete}
            />
          </div>
      </Dialog>
  );
};

export default GroupEdit;
