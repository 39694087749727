const isDebug = import.meta.env.VITE_APP_DEBUG ?? true;

export default {
  debug: (...args: any) => {
    if (!isDebug) return;
    console.debug(...args);
  },
  error: (...args: any) => {
    console.error(...args);
  },
  info: (...args: any) => {
    console.info(...args);
  },
};
