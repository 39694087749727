import {
  EntityEnum,
  UserFieldsFragment,
  UserAttributeInsertInput,
  GroupFieldsFragment,
  Sdk,
} from "graphql/generated/graphqlRequest";

export interface UserRoleOption {
  label: string;
  value: string;
}

export const userRoleOptions: UserRoleOption[] = [
  { label: "user", value: "user" },
  { label: "founder", value: "founder" },
  { label: "branch", value: "branch" },
  { label: "blocked", value: "blocked" },
  { label: "admin", value: "admin" },
];


export function formatRidingSince(ridingSince?: string): string {
  if (!ridingSince) {
    return "Working On It";
  }

  const yearsRiding =
    new Date().getFullYear() - new Date(ridingSince).getFullYear();

  let body =
    yearsRiding < 1
      ? "< 1 year"
      : `${yearsRiding} year${yearsRiding > 1 ? "s" : ""}`;
  if (Number.isNaN(yearsRiding)) {
    body = ridingSince;
  }
  return body;
}

export function valueForUserAttribute(
  name: string,
  user?: UserFieldsFragment,
  type?: "date" | "ridingSince" | "dateOfBirth"
): string {
  const value = user?.attributes.find((attr) => attr.name === name)?.value;
  if (type === "date" && value) {
    return new Date(value).toLocaleDateString();
  }
  if (type === "ridingSince" && value) {
    return formatRidingSince(value);
  }
  if (type === "dateOfBirth" && value) {
    return new Date(value).toLocaleDateString("en-US", {
      timeZone: "UTC"
    });
  }
  return value ?? "";
}

export function createUserAttribute(
  name: string,
  value: string,
  userId: string
): UserAttributeInsertInput {
  return {
    name,
    value,
    userId,
    entityName: EntityEnum.User,
  };
}

export function userLocationName(user?: UserFieldsFragment): string {
  const role = valueForUserAttribute("hasuraRole", user);
  if (role === "branch") {
    const groups = groupsWithRoles(["branch"], user);
    return groups.map(group => group.description ?? "").join(" ");
  }
  return user?.homeLocation?.name ?? "";
}

export function userCollectiveName(user?: UserFieldsFragment): string {
  const groups = groupsWithRoles(["branch", "founder"], user);
  return groups.map(group => group.description ?? "").join(" ");
}

export function groupsWithRoles(roles: string[], user?: UserFieldsFragment): GroupFieldsFragment[] {
  return user?.userGroups?.filter(userGroup => roles.includes(userGroup.metadata?.role)).map(userGroup => userGroup.group) ?? [];
}

// mutation { 
// 	updateUserGroup(where:{metadata:{_eq:{role:"user"}}} _set:{metadata:{}}) {
//     affectedRows
//   }
// }

export async function updateUserGroupsWithRole(sdkClient: Sdk, newRole: string, userId: string, newGroupId?: string) {
  if (["user", "blocked", "admin"].includes(newRole)) {
    return await sdkClient.updateUserGroups({
      where: { userId: { _eq: userId } },
      _set: { metadata: {} },
    });
  }

  if (["branch", "founder"].includes(newRole)) {
    if (!newGroupId) {
      throw new Error(`Role is ${newRole} and no Collective is selected. Please update and try again.`);
    }
    if (newRole === "branch") {
      const existingResult = await sdkClient.userGroups({ where: { groupId: { _eq: newGroupId }, metadata: { _eq: { role: "branch" }} } });
      const existingUserGroup = existingResult.userGroups[0]
      if (existingUserGroup && existingUserGroup.userId !== userId) {
        throw new Error(`${existingUserGroup.group.name} already has a branch account assigned.`);
      }
    }
    await sdkClient.updateUserGroups({
      where: { userId: { _eq: userId }, groupId: { _neq: newGroupId } },
      _set: { metadata: {} },
    });

    const upsertResult = await sdkClient.upsertUserGroup({
      object: {
        userId: userId,
        groupId: newGroupId,
        metadata: { role: newRole },
      },
    });
    if (upsertResult.insertUserGroup?.metadata.role !== newRole) {
      throw new Error("A problem happened updating the user's role. Please try again.");
    }    
  }
  return
}
