import React from "react";

import GroupsTable from "components/Group/GroupsTable";
import { useSearchParamsToTableState } from "hooks/useSearchParamsToTableState";

export const GroupsPage: React.FC = () => {
  const { tableState, updateSearchParams } = useSearchParamsToTableState();
  return <GroupsTable tableStateOverride={tableState} onTableStateEvent={updateSearchParams} />;
};

export default GroupsPage;
