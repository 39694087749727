import React from "react";
import BikesTable from "components/Bike/BikesTable";
import { useSearchParamsToTableState } from "hooks/useSearchParamsToTableState";

export const BikesPage: React.FC = () => {
  const { tableState, updateSearchParams } = useSearchParamsToTableState();
  return <BikesTable tableStateOverride={tableState} onTableStateEvent={updateSearchParams} />;
};

export default BikesPage;
