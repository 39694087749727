/* eslint-disable no-await-in-loop */
import React, { useState, useRef } from "react";
import {
  AdminTable,
  AdminTableState,
  dateBody,
  dateFilterTemplate,
  rowToNewTab,
} from "components/Admin";
import { Column } from "primereact/column";
import { DataTableSelectEvent } from "primereact/datatable";
import { Toast } from "primereact/toast";
import {
  type PostCommentFieldsFragment,
  PostAdminTableFieldsFragment,
} from "graphql/generated/resourceApi";
import { PostCommentAdminTableFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import { useDataAdapter } from "hooks/useDataAdapter";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { formatTextWithMentions } from "utils/postUtils";

export interface PostCommentsTableProps {
  onRowClick: (row: PostCommentFieldsFragment, path?: string) => void;
  post: PostAdminTableFieldsFragment;
  hideHeader?: boolean;
  tableHeight?: number;
}

const PostCommentsTable: React.FC<PostCommentsTableProps> = ({
  onRowClick,
  post,
  hideHeader,
  tableHeight,
}) => {
  const toast = useRef<Toast>(null);
  const { tableAdapter } = useDataAdapter(
    "postComment",
    PostCommentAdminTableFieldsFragmentDoc,
    { postId: { _eq: post.id } }
  );

  const initialFilters = {
    createdAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_BEFORE }],
    },
  };

  const initialState: AdminTableState = {
    page: 0,
    rowsPerPage: 50,
    filters: initialFilters,
    sortField: "createdAt",
    sortOrder: -1,
    globalFilter: "",
    globalFilterFields: ["body", "post.user.name"],
  };
  const [tableState, setTableState] = useState<AdminTableState>({
    ...initialState,
  });

  return (
    <div>
      <Toast ref={toast} />
      {!hideHeader && (
        <div className="flex justify-between items-center">
          <div className="flex justify-between items-center p-text-primary">
            <i className="pi pi-postComment text-2xl mr-3" />
            <h1 className="text-3xl font-bold m-4">Post Comments</h1>
          </div>
        </div>
      )}

      <AdminTable
        initialState={initialState}
        stateOverride={tableState}
        onStateEvent={setTableState}
        adapter={tableAdapter}
        includeColumnsToggler={!hideHeader}
        includeGlobalFilter={!hideHeader}
        globalFilterPlaceholder="Search comment by comment body"
        className="mb-3"
        filterDisplay="menu"
        size="small"
        selectionMode="single"
        tableHeight={tableHeight}
        onRowSelect={(e: DataTableSelectEvent) =>
          rowToNewTab(e, `/admin/postComments/${e.data.id}`, onRowClick)
        }
      >
        <Column
          field="body"
          header="Body"
          sortable
          filter
          body={(row) => formatTextWithMentions(row.body)}
        />
        <Column
          field="userPostCommentReactionsAggregate.aggregate.count"
          header="Reactions"
          sortable
        />
        <Column
          field="postCommentCommentsAggregate.aggregate.count"
          header="Comments"
          sortable
        />
        <Column
          field="userPostCommentReportsAggregate.aggregate.count"
          header="Reports"
          sortable
        />
        <Column
          field="createdAt"
          header="Created On"
          body={dateBody}
          filterElement={dateFilterTemplate}
          dataType="date"
          filterType="date"
          sortable
          filter
        />
      </AdminTable>
    </div>
  );
};

export default PostCommentsTable;
