import { Calendar } from "primereact/calendar";
import {
  ColumnBodyOptions,
  ColumnFilterElementTemplateOptions,
} from "primereact/column";
import { TriStateCheckbox, TriStateCheckboxChangeEvent } from 'primereact/tristatecheckbox';
import React, { ReactNode } from "react";
import { moneyFormat } from "utils/formatters";
import { valueForPath } from "./adminTableUtils";
import { Avatar } from "primereact/avatar";

export const imageSize = 96;

export const dateFilterTemplate = (
  options: ColumnFilterElementTemplateOptions
): ReactNode => {
  return (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
      mask="99/99/9999"
    />
  );
};

export const dateBody = (data: any, options: ColumnBodyOptions): string => {
  if (!data) {
    return "";
  }
  const path = options.field.split(".");
  const value = valueForPath(path, data);
  if (value && options.field.includes("dateOfBirth")) {
    const date = new Date(value).toLocaleDateString("en-US", {
      timeZone:"UTC"
    });
    return date;
  }
  if (value) {
    const date = new Date(value).toLocaleDateString();
    return date;
  }
  return "";
};

export const dateTimeBody = (data: any, options: ColumnBodyOptions): string => {
  if (!data) {
    return "";
  }
  const value = valueForPath(options.field.split("."), data);
  if (value) {
    const time = new Date(value).toLocaleTimeString();
    return `${dateBody(data, options)} ${time}`;
  }
  return "";
};

export const moneyBody = (data: any, options: ColumnBodyOptions): string => {
  if (!data) {
    return "";
  }
  const value = valueForPath(options.field.split("."), data);
  if (value) {
    return moneyFormat.format(parseFloat(value));
  }
  return "";
};

export const booleanBody = (data: any, options: ColumnBodyOptions): string => {
  return data[options.field] ? "Yes" : "No";
};

export const getInitial = (value?: string): string =>
    value?.charAt(0).toUpperCase() || "";

export const imageBody = (token?: string, getInitials?: (data: any) => string) => {
  return (data: any, options: ColumnBodyOptions): React.ReactNode => {
    let url = valueForPath(options.field.split("."), data);
    if (url && token) {
      url = `${url}&token=${token}`
    }
    if (url) {
      return (
        <img
          src={url}
          width={imageSize}
          height={imageSize}
          style={{ borderRadius: "6px", objectFit: "cover", aspectRatio: 1 }}
        />
      );
    }
    const initials = getInitials?.(data) ?? "";
    return (
      <Avatar label={initials} shape="square" style={{ borderRadius: "6px", width: imageSize, height: imageSize }} />
    );
  }
};

export const booleanFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
  return (
    <TriStateCheckbox value={options.value} onChange={(e: TriStateCheckboxChangeEvent) => options.filterCallback(e.value)} />
  );
};
