import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AdminInputBoolean, AdminInputRelation, AdminInputRichText } from "components/Admin";
import { Button } from "primereact/button";
import { useUserContext } from "shared/UserContext";

import { Message } from "primereact/message";
import { useDataAdapter } from "hooks/useDataAdapter";
import useFileApi from "hooks/useUploadImage";
import {
  GroupFieldsFragment,
  GroupFieldsFragmentDoc,
  PostAdminTableFieldsFragment,
} from "graphql/generated/graphqlRequest";

export interface PostEditProps {
  post: PostAdminTableFieldsFragment;
  onUpdate: ({
    title,
    message,
    post,
  }: {
    title: string;
    message: string;
    post?: PostAdminTableFieldsFragment;
  }) => void;
}

interface PostFormData {
  groupId: string;
  isHiddenByReport: boolean;
  isSilentlyHidden: boolean;
  notes: string;
}

const PostEdit: React.FC<PostEditProps> = ({ post, onUpdate }) => {
  const { sdkClient, token: authToken } = useUserContext();
  const { uploadImage } = useFileApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [group, setGroup] = useState<GroupFieldsFragment | undefined>(
    undefined
  );
  const [unsavedImage, _setUnsavedImage] = useState<File | undefined>(undefined);
  const [imageShouldBeDeleted, _setImageShouldBeDeleted] = useState(false);

  const { dataAdapter: groupsDataAdapter } = useDataAdapter(
    "group",
    GroupFieldsFragmentDoc
  );

  const defaultValues: PostFormData = useMemo(() => {
    return {
      groupId: post?.groupId ?? "",
      isHiddenByReport: post?.isHiddenByReport ?? false,
      isSilentlyHidden: post?.isSilentlyHidden ?? false,
      notes: post.metadata?.notes ?? "",
    };
  }, [post]);

  const { control, handleSubmit, watch } = useForm<PostFormData>({
    defaultValues,
  });
  const watchGroupId = watch("groupId", "");

  useEffect(() => {
    if (watchGroupId) {
      sdkClient.groupById({ id: watchGroupId }).then((result) => {
        setGroup(result.groupById ?? undefined);
      });
    }
  }, [watchGroupId]);

  const handleError = (error: string) => {
    setError(error);
    setLoading(false);
  };

  const onSubmit: SubmitHandler<PostFormData> = async (data) => {
    setError("");
    setLoading(true);

    let postImageId: string | null | undefined = post?.postImages[0]?.file?.id;

    if (unsavedImage) {
      const result = await uploadImage(unsavedImage, authToken, {
        isPublic: true,
      });
      postImageId = result?.id ?? postImageId;
    } else if (imageShouldBeDeleted) {
      postImageId = null;
    }

    try {
      const result = await sdkClient.updatePostAdmin({
        id: post.id,
        _set: {
          groupId: group?.id,
          isSilentlyHidden: data.isSilentlyHidden,
          isHiddenByReport: data.isHiddenByReport,
        },
        _append: {
          metadata: {
            notes: data.notes,
          }
        }
      });

      const updatedPost = result.updatePostAdmin;
      if (!updatedPost) {
        return handleError("Unable to update post");
      }
      setLoading(false);
      onUpdate({ post: updatedPost, title: "Post Updated", message: `Post has been successfully updated` });
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 mt-8 w-600"
      >
        <AdminInputRelation
          label="Collective"
          name="groupId"
          adapter={groupsDataAdapter}
          relationshipColumnNameForLabel="description"
          relationshipColumnNameForValue="id"
          sortField="description"
          sortOrder={1}
          control={control}
        />
        <AdminInputBoolean
          name="isSilentlyHidden"
          label="Shadow Banned"
          checked={defaultValues.isSilentlyHidden}
          control={control}
        />
        <AdminInputBoolean
          name="isHiddenByReport"
          label="Hidden"
          checked={defaultValues.isHiddenByReport}
          control={control}
        />
        <AdminInputRichText
          name="notes"
          label="Notes"
          style={{ height: '300px' }}
          control={control}
        />
        {/* <ImageField
          file={{ url: post.postImages[0]?.file?.url }}
          setUnsavedImage={setUnsavedImage}
          unsavedImage={unsavedImage}
          setImageShouldBeDeleted={setImageShouldBeDeleted}
        /> */}
        {error && <Message severity="error" text={error} />}
        <Button type="submit" label="Save" loading={loading} />
      </form>
    </>
  );
};

export default PostEdit;
