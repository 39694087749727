import {
  AdminInputBoolean,
  AdminInputEmail,
  AdminInputRelation,
  AdminInputSelect,
  AdminInputText,
} from "components/Admin";
import AdminInputTextArea from "components/Admin/AdminForm/AdminFormInputs/AdminInputTextArea";
import { ImageField } from "components/ImageField";
import { LocationField } from "components/LocationForm/LocationField";
import { GroupFieldsFragmentDoc } from "graphql/generated/graphqlRequest";
import {
  GroupFieldsFragment,
  LocationTypeEnum,
  UserFieldsFragment,
} from "graphql/generated/resourceApi";
import { useDataAdapter } from "hooks/useDataAdapter";
import useFileApi from "hooks/useUploadImage";
import { Button } from "primereact/button";
import { Message } from "primereact/message";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useUserContext } from "shared/UserContext";
import {
  createUserAttribute,
  groupsWithRoles,
  updateUserGroupsWithRole,
  userRoleOptions,
  valueForUserAttribute,
} from "utils/userUtils";

export interface UserEditProps {
  user?: UserFieldsFragment;
  onUpdate: ({
    title,
    message,
    user,
  }: {
    title: string;
    message: string;
    user?: UserFieldsFragment;
  }) => void;
}

interface UserFormData {
  email: string;
  emailIsVerified: boolean;
  role: string;
  firstName: string;
  lastName: string;
  groupId: string;
  pronouns: string;
  phoneNumber: string;
  dateOfBirth: string;
  website: string;
  instagramLink: string;
  ridingSince: string;
  bio: string;
}

const UserEdit: React.FC<UserEditProps> = ({ user, onUpdate }) => {
  const { sdkClient, token: authToken } = useUserContext();
  const { uploadImage } = useFileApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [location, setLocation] = useState(user?.homeLocation);
  const [group, setGroup] = useState<GroupFieldsFragment | undefined>(
    undefined
  );
  const [unsavedImage, setUnsavedImage] = useState<File | undefined>(undefined);
  const [imageShouldBeDeleted, setImageShouldBeDeleted] = useState(false);

  const { dataAdapter: groupsDataAdapter } = useDataAdapter(
    "group",
    GroupFieldsFragmentDoc
  );

  user?.profileImage;

  const originalValues: UserFormData = useMemo(() => {
    const role = valueForUserAttribute("hasuraRole", user) || "user";
    let groupId = "";
    if (role === "branch" || role === "founder") {
      groupId = groupsWithRoles([role], user)[0]?.id ?? "";
    }
    return {
      email: user?.primaryEmail ?? "",
      emailIsVerified: user?.primaryEmailIsVerified ?? false,
      role: role,
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      groupId: groupId,
      pronouns: valueForUserAttribute("pronouns", user),
      phoneNumber: valueForUserAttribute("phoneNumber", user),
      dateOfBirth: valueForUserAttribute("dateOfBirth", user),
      website: valueForUserAttribute("website", user),
      instagramLink: valueForUserAttribute("instagramLink", user),
      ridingSince: valueForUserAttribute("ridingSince", user),
      bio: valueForUserAttribute("bio", user),
    };
  }, [user]);

  const { control, handleSubmit, watch, setValue } = useForm<UserFormData>({
    defaultValues: originalValues,
  });
  const watchRole = watch(
    "role",
    valueForUserAttribute("hasuraRole", user) || "user"
  );
  const watchGroupId = watch("groupId", "");

  useEffect(() => {
    if (watchGroupId) {
      sdkClient.groupById({ id: watchGroupId }).then((result) => {
        setGroup(result.groupById ?? undefined);
      });
    }
  }, [watchGroupId]);

  useEffect(() => {
    if (group && watchRole === "branch") {
      setValue("firstName", "The Litas");
      setValue("lastName", group.name);
    }
  }, [group, watchRole]);

  const handleError = (error: string) => {
    setError(error);
    setLoading(false);
  };

  const onSubmit: SubmitHandler<UserFormData> = async (data) => {
    setError("");
    setLoading(true);

    let profileImageId: string | null | undefined = user?.profileImage?.id;

    if (unsavedImage) {
      const result = await uploadImage(unsavedImage, authToken, {
        isPublic: true,
      });
      profileImageId = result?.id ?? profileImageId;
    } else if (imageShouldBeDeleted) {
      profileImageId = null;
    }

    try {
      let userId = user?.id;
      if (!userId) {
        const insertUserResult = await sdkClient.insertUser({
          object: { primaryEmail: data.email },
        });
        userId = insertUserResult.insertUser?.id;
        if (!userId) {
          return handleError("Unable to create user");
        }
      }

      let homeLocationId = user?.homeLocation?.id;
      if (location) {
        const locationResult = await sdkClient.upsertUserLocation({
          object: {
            id: user?.homeLocation?.id,
            userId: userId,
            name: location.name,
            latitude: location.latitude,
            longitude: location.longitude,
            type: LocationTypeEnum.Home,
          },
        });
        homeLocationId =
          locationResult.upsertUserLocation?.id ?? homeLocationId;
        if (!homeLocationId) {
          throw new Error(
            "A problem happened saving the user's location. Please try again."
          );
        }
      }

      const groupId = group?.id ?? data.groupId;
      await updateUserGroupsWithRole(sdkClient, data.role, userId, groupId);

      const updateUserResult = await sdkClient.updateUserFull({
        userId: userId,
        userInput: {
          primaryEmail: data.email,
          primaryEmailIsVerified: data.emailIsVerified,
          firstName: data.firstName,
          lastName: data.lastName,
          profileImageId: profileImageId,
          homeLocationId: homeLocationId,
        },
        attributesInput: [
          createUserAttribute("hasuraRole", data.role, userId),
          createUserAttribute("pronouns", data.pronouns, userId),
          createUserAttribute("phoneNumber", data.phoneNumber, userId),
          createUserAttribute("dateOfBirth", data.dateOfBirth, userId),
          createUserAttribute("website", data.website, userId),
          createUserAttribute("instagramLink", data.instagramLink, userId),
          createUserAttribute("ridingSince", data.ridingSince, userId),
          createUserAttribute("bio", data.bio, userId),
        ],
      });

      const updatedUser = updateUserResult.updateUserFull;
      if (!updatedUser) {
        throw new Error("Unable to create user");
      }
      setLoading(false);
      onUpdate({
        user: updatedUser,
        title: "User Updated",
        message: `${data.email} has been successfully updated`,
      });
    } catch (error) {
      if (error.message.includes("Uniqueness violation")) {
        return handleError("User already exists");
      }
      return handleError(error.message);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 mt-8 w-600"
      >
        <AdminInputEmail name="email" required control={control} />
        <AdminInputBoolean
          name="emailIsVerified"
          label="Email Verified"
          checked={originalValues.emailIsVerified}
          control={control}
        />
        <AdminInputSelect
          name="role"
          required
          options={userRoleOptions}
          showClear={false}
          control={control}
        />
        {["founder", "branch"].includes(watchRole) && (
            <>
              <AdminInputRelation
                label="Collective"
                name="groupId"
                adapter={groupsDataAdapter}
                relationshipColumnNameForLabel="description"
                relationshipColumnNameForValue="id"
                sortField="description"
                sortOrder={1}
                control={control}
              />
            </>
          )}
        <AdminInputText name="firstName" label="First Name" control={control} />
        <AdminInputText name="lastName" label="Last Name" control={control} />

        {watchRole !== "branch" && (
          <>
            <AdminInputText name="pronouns" control={control} />
            <AdminInputText
              name="phoneNumber"
              label="Phone Number"
              control={control}
            />
            <AdminInputText
              name="dateOfBirth"
              label="Birth Date"
              control={control}
            />
            <AdminInputText
              name="ridingSince"
              label="Riding Since"
              control={control}
            />
            <AdminInputText name="website" control={control} />
          </>
        )}
        <AdminInputText
          name="instagramLink"
          label="Instagram Handle"
          control={control}
        />
        <AdminInputTextArea name="bio" label="Bio" control={control} />
        {watchRole !== "branch" && (
          <LocationField location={location} setLocation={setLocation} />
        )}

        <ImageField
          file={{ url: user?.profileImage?.url }}
          setUnsavedImage={setUnsavedImage}
          unsavedImage={unsavedImage}
          setImageShouldBeDeleted={setImageShouldBeDeleted}
        />
        {error && <Message severity="error" text={error} />}
        <Button type="submit" label="Save" loading={loading} />
      </form>
    </>
  );
};

export default UserEdit;
