import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { usePublicMapQuery } from "graphql/generated/resourceApi";
import { useClient } from "shared/UserContext";
import { collectiveIcon } from "utils/leaflet/collectiveIcon";

interface MapViewCollectivesProps {
  center?: [number, number] 
  zoom?: number
  scrollWheelZoom?: boolean
}

export const MapViewCollectives: React.FC<MapViewCollectivesProps> = ({center, zoom, scrollWheelZoom}) => {
  const client = useClient();
  const { data } = usePublicMapQuery(client, {
    where: { isInterestCollective: { _eq: false } },
  });

  console.log('MapViewCollectives', data);

  return (
    <MapContainer
      center={center ?? [40, -40]}
      zoom={zoom ?? 2}
      scrollWheelZoom={scrollWheelZoom ?? false}
      style={{ width: "100%", height: "100%" }}
    >
      <TileLayer
        //attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data?.groups.map((value) => {
        if (value.location?.latitude && value.location?.longitude)
          return (
            <Marker
              position={[value.location?.latitude, value.location?.longitude]}
              key={`marker-${value.id}`}
              icon={collectiveIcon}
            >
              <Popup>{value.location.name}</Popup>
            </Marker>
          );
        return null;
      })}
    </MapContainer>
  );
};
