import {nanoid} from 'nanoid';

export type FormattedText =
  | FormattedTextNoLink
  | FormattedTextHashtag
  | FormattedTextMention
  | FormattedTextWebLink;

export interface FormattedTextNoLink {
  key?: string;
  displayText: string;
  type: 'NO_LINK';
  rawValue: string;
}

export interface FormattedTextHashtag {
  key?: string;
  displayText: string;
  type: 'HASHTAG';
  rawValue: string;
  linkValue: string;
}

export interface FormattedTextMention {
  key?: string;
  displayText: string;
  type: 'MENTION';
  rawValue: string;
  linkValue: string;
}

export interface FormattedTextWebLink {
  key?: string;
  displayText: string;
  type: 'WEB_LINK';
  rawValue: string;
  linkValue: string;
}

const urlRegex = /^https:\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/;
const hashtagRegex = /(?:^|\s)(#\w+)/g; // hashtag at start of line or after whitespace
const mentionPartsRegex = /@\[([^\[\]\(\){}]+)\]\((\S+)\)/g; // mention: @[name](id)
const mentionsRegex = /(@\[[^\[\]\(\){}]+\]\(\S+\))/g; // mention: @[name](id)
const mentionKeywordsRegex = /@[^\[\]\(\){}]+ {0,1}[^\[\]\(\){}]*/g; // mention keyword: @firstname lastname
const possibleUrlRegex = /(\S+\.[^\s\.]{2,})/g; // possible url: no whitespace and has at least one period not at the end

export function isValidHttpsUrl(text: string) {
  return urlRegex.test(text);
}

function textToFormattedLink(text: string): FormattedText | undefined {
  if (text.startsWith('#')) {
    return {
      key: nanoid(),
      displayText: text,
      type: 'HASHTAG',
      rawValue: text,
      linkValue: text,
    };
  }
  if (text.startsWith('@')) {
    let subMatches = text.matchAll(mentionPartsRegex);
    let value = subMatches.next().value;
    const name = value?.[1];
    const id = value?.[2];
    if (name && id) {
      return {
        key: nanoid(),
        displayText: `@${name}`,
        type: 'MENTION',
        rawValue: text,
        linkValue: id,
      };
    }
  }

  let textWithHttps = text;
  if (textWithHttps.startsWith('http://')) {
    textWithHttps = text.replace('http://', 'https://');
  } else if (!textWithHttps.startsWith('https://')) {
    textWithHttps = `https://${text}`;
  }
  if (isValidHttpsUrl(textWithHttps)) {
    return {
      key: nanoid(),
      displayText: text,
      type: 'WEB_LINK',
      rawValue: text,
      linkValue: textWithHttps,
    };
  }
  return undefined;
}

export function getMentionKeyword(text: string, cursorIndex: number): string {
  const matches = Array.from(text.matchAll(mentionKeywordsRegex));

  for (const match of matches) {
    const test = match[0];
    const index = text.indexOf(test, match.index);
    if (cursorIndex >= index && cursorIndex <= index + test.length) {
      const keyword = text.slice(index, cursorIndex);
      return keyword;
    }
  }
  return '';
}

export function textToFormattedTexts(text: string): FormattedText[] {
  const regex = new RegExp(
    `${hashtagRegex.source}|${mentionsRegex.source}|${possibleUrlRegex.source}`,
    'gm',
  );

  const placeholder = '#placeholder#';
  let textWithPlaceholders = text;

  const matches = Array.from(text.matchAll(regex));
  const formattedLinks: FormattedText[] = [];
  for (const match of matches) {
    const test = match[0].trim();
    const formattedLink = textToFormattedLink(test);
    if (formattedLink) {
      textWithPlaceholders = textWithPlaceholders.replace(test, placeholder);
      formattedLinks.push(formattedLink);
    }
  }

  if (!formattedLinks.length) {
    return [
      {key: nanoid(), displayText: text, type: 'NO_LINK', rawValue: text},
    ];
  }

  const formattedTexts: FormattedText[] = [];
  let testText = textWithPlaceholders;

  formattedLinks.forEach((link, index) => {
    const nextPlaceholderStart = testText.indexOf(placeholder);
    const noLinkText = testText.slice(0, nextPlaceholderStart);
    if (noLinkText.length) {
      formattedTexts.push({
        key: nanoid(),
        displayText: noLinkText,
        type: 'NO_LINK',
        rawValue: noLinkText,
      });
    }
    formattedTexts.push(link);
    testText = testText.slice(nextPlaceholderStart + placeholder.length);
    if (index === formattedLinks.length - 1 && testText.length) {
      formattedTexts.push({
        key: nanoid(),
        displayText: testText,
        type: 'NO_LINK',
        rawValue: testText,
      });
    }
  });

  return formattedTexts;
}

export function replaceAtIndexAddingSpace(
  haystack: string,
  needle: string,
  replacement: string,
  index: number,
): string {
  let searchPosition = -1;
  let found = false;
  while (!found) {
    const currentSearchPosition = haystack.indexOf(needle, searchPosition + 1);
    if (currentSearchPosition < 0 && searchPosition < 0) {
      return haystack;
    }
    if (currentSearchPosition <= index) {
      if (
        currentSearchPosition === searchPosition ||
        currentSearchPosition === -1
      ) {
        found = true;
      } else {
        searchPosition = currentSearchPosition;
      }
    } else {
      found = true;
    }
  }

  const before = haystack.slice(0, searchPosition);
  let afterReplacementIndex = before.length + needle.length;
  if (haystack[afterReplacementIndex] === ' ') {
    afterReplacementIndex += 1;
  }
  const after = haystack.slice(afterReplacementIndex);
  const newValue = `${before}${replacement} ${after}`;
  return newValue;
}

export function calculateRawValueCursorLocation(
  formattedTexts: FormattedText[],
  cursorLocation: number,
): number {
  let location = cursorLocation;
  let testLocation = 0;
  for (const formattedText of formattedTexts) {
    testLocation += formattedText.displayText.length;
    if (testLocation <= location) {
      location +=
        formattedText.rawValue.length - formattedText.displayText.length;
    } else {
      break;
    }
  }
  return location;
}
