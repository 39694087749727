import React from 'react'

interface DashboardCardProps {
  tagLabel: string
  iconClassName: string // pi pi-user
  mainText: string
  secondaryText: string
  onClick?: () => void
}
export const DashboardCard: React.FC<DashboardCardProps> = ({ tagLabel, iconClassName, mainText, secondaryText, onClick }) => (
  <a
    className="hover:[background-color:var(--primary-color-hover)] hover:[color:var(--primary-color-text)] transition duration-200 shadow-xl rounded-lg p-surface-header col-span-12 sm:col-span-6 xl:col-span-3 p-text-primary"
    onClick={onClick}
    href="#"
  >
    <div className="p-5">
      <div className="flex justify-between">
        <div className="flex flex-row items-center">
          <i className={`${iconClassName} text-2xl mr-2`} />
          <div className="text-xl font-bold">{secondaryText}</div>
        </div>
        <div className="rounded-full h-6 px-2 flex justify-items-center p-background-primary-color font-semibold text-sm p-primary-text-color">
          <span className="flex items-center">{tagLabel}</span>
        </div>
      </div>
      <div className="ml-2 w-full flex-1">
        <div>
          <div className="mt-3 text-3xl font-bold leading-8">{mainText}</div>
        </div>
      </div>
    </div>
  </a>
)
